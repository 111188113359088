import { useContext } from "react";
import { Link } from "react-router-dom";
import { priceFinal } from "../helpers/functions";
import { AppContext } from "../providers/AppContextProvider";
import { InCartItem } from "../providers/CartContextProvider";

interface CartDetailsItemProps {
  item: InCartItem;
  onRemove: () => void;
}

export const CartDetailsItem = ({ item, onRemove }: CartDetailsItemProps) => {
  const appContext = useContext(AppContext);

  const getPriceText = () => {
    return `${appContext.location.currencySign}${item.price.price}`;
  };
  const getFinalPriceText = () => {
    return `${appContext.location.currencySign}${priceFinal(item.price)}`;
  };

  return (
    <tr className="cart-table-primary">
      <td>
        <div className="cart-article">
          <img src={item.image?.path} alt="" />
          <Link to="/">{item.title}</Link>
        </div>
      </td>
      {window.innerWidth >= 768 ? ( //This is ugly AF but hey, aren't tables too? 💀
        <>
          <td>
            <div className="cart-quantity">
              {/* <button className="button-left btn">-</button>
              <input type="text" value={item.quantity} />
              <button className="button-right btn">+</button> */}
            </div>
          </td>

          <td>{getPriceText()}</td>
          <td className="subtotal">{getFinalPriceText()}</td>

          <td>
            <button
              className="cart-remove-item tooltip top"
              onClick={onRemove}
              type="button"
            >
              <span className="tiptext">Quitar</span>
              <i className="fa fa-times"></i>
            </button>
          </td>
        </>
      ) : (
        <div>
          <td>
            <div className="cart-quantity">
              {/* <button className="button-left btn">-</button> */}
              {/* <input type="text" value={item.quantity} /> */}
              {/* <button className="button-right btn">+</button> */}
            </div>
          </td>

          <td>{getPriceText()}</td>
          <td className="subtotal">{getFinalPriceText()}</td>

          <td>
            <button
              type="button"
              className="cart-remove-item tooltip top"
              onClick={onRemove}
            >
              <span className="tiptext">Quitar</span>
              <i className="fa fa-times"></i>
            </button>
          </td>
        </div>
      )}
    </tr>
  );
};
