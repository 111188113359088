import { useContext, useState } from "react";
import { AuthUser } from "../../../../shared/types";
import { UserActions } from "../../actions/user";
import { DEFAULT_USER } from "../../helpers/defaults";
import { AppContext } from "../../providers/AppContextProvider";
import Input from "../Input/Input";

const PersonalTab = () => {
  const appContext = useContext(AppContext);

  const [user, setUser] = useState<AuthUser>(
    appContext.user ?? { ...DEFAULT_USER, token: "" }
  );

  // const months = [
  //   "Enero",
  //   "Febrero",
  //   "Marzo",
  //   "Abril",
  //   "Mayo",
  //   "Junio",
  //   "Julio",
  //   "Agosto",
  //   "Septiembre",
  //   "Octubre",
  //   "Noviembre",
  //   "Diciembre",
  // ];

  // function handleDates(type?: any, number?: any) {
  //   const arr = [];
  //   if (type === "year") {
  //     const endYear = new Date().getFullYear();
  //     for (let i = endYear; i >= number; i--) {
  //       arr.push(
  //         <option key={i} value={i}>
  //           {i}
  //         </option>
  //       );
  //     }
  //     return arr;
  //   } else {
  //     for (let i = 1; i <= number; i++) {
  //       arr.push(
  //         <option key={i} value={i}>
  //           {i}
  //         </option>
  //       );
  //     }
  //     return arr;
  //   }
  // }

  // const handleChange = (e: any) => {
  //   console.log(e.target.value);
  // };

  const handleSave = async () => {
    await UserActions.put({ ...user });

    appContext.setUser(user);
  };

  return (
    <div id="Datos Personales">
      <div className="form">
        <p>(*) Indica campos requeridos.</p>
        <div className="registration-wrapper">
          <Input
            type="email"
            label="Email *"
            onChange={(value) => setUser({ ...user, email: value })}
            value={user.email}
          />
          <Input
            type="text"
            label="Nombre *"
            onChange={(value) => setUser({ ...user, firstname: value })}
            value={user.firstname}
          />
          <Input
            type="text"
            label="Apellido *"
            onChange={(value) => setUser({ ...user, lastname: value })}
            value={user.lastname}
          />
        </div>
        <div className="registration-wrapper">
          <div>
            <Input
              type="tel"
              label="Teléfono"
              onChange={(value) => setUser({ ...user, phoneNumber: value })}
              value={user.phoneNumber}
            />
          </div>
          <div>
            <Input
              type="tel"
              label="Teléfono Movil"
              onChange={(value) => setUser({ ...user, mobileNumber: value })}
              value={user.mobileNumber}
            />
          </div>
        </div>
        {/* <div className="registration-dates-container registration-wrapper">
          <span className="section-title">Fecha de nacimiento</span>
          <div className="registration-dates-options">
            <div style={{ display: "flex" }}>
              <label htmlFor="">
                Día
                <select onChange={handleChange} name="day">
                  <option>Dia</option>
                  {handleDates("month", 31)}
                </select>
              </label>
            </div>
            <div style={{ display: "flex" }}>
              <label>
                Mes
                <select onChange={handleChange} name="month">
                  <option>Mes</option>
                  {months.map((month, idx) => {
                    return (
                      <option key={idx} value={month}>
                        {month}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            <div style={{ display: "flex" }}>
              <label htmlFor="">
                Año
                <select onChange={handleChange} name="year">
                  <option>Año</option>
                  {handleDates("year", 1900)}
                </select>
              </label>
            </div>
          </div>
        </div> */}
        <div className="registration-wrapper">
          <Input
            label="Documento de identidad (DNI, CI, CC, etc.)"
            type="text"
            onChange={(value) => setUser({ ...user, uid: value })}
            value={user.uid}
          />
          <Input
            label="Razón social"
            type="text"
            onChange={(value) => setUser({ ...user, companyName: value })}
            value={user.companyName}
          />
          <Input
            label="CUIT"
            type="text"
            onChange={(value) => setUser({ ...user, cuit: value })}
            value={user.cuit}
          />
          <button className="form-button" onClick={handleSave}>
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalTab;
