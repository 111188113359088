import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { Video } from "../../../../shared/types";
import EditMode, { PreviewType } from "./EditMode";
import { DEFAULT_VIDEO } from "../../helpers/defaults";
import FileInput from "./FileInput";

const useStyles = makeStyles((theme) => ({
  videoread: {
    textAlign: "left",
    width: "100%",
  },
  title: {
    textAlign: "left",
    width: "50%",
    paddingBottom: "10px",
  },
  description: {
    textAlign: "left",
    width: "90%",
    paddingBottom: "10px",
  },
  image: {
    paddingBottom: "10px",
  },
  input: {
    display: "none",
  },
}));

interface VideoReadProps {
  video?: Video;
  onSave: (video: Video) => void;
  onCancel: () => void;
  onPreview: (video: Video, type: PreviewType) => void;
}

const VideoRead = (props: VideoReadProps) => {
  const classes = useStyles();

  const [video, setVideo] = useState<Video>(props.video ?? DEFAULT_VIDEO);

  const handlePictureAdd = (path: string) => {
    setVideo({ ...video, path });
  };

  const handlePictureRemove = () => {
    setVideo({ ...video, path: "" });
  };

  return (
    <EditMode
      onSave={() => props.onSave(video)}
      onCancel={() => props.onCancel}
      //onPreview={(type) => props.onPreview(video, type)}
    >
      <div className={classes.videoread}>
        <div className={classes.image}>
          <FileInput
            type={"video"}
            path={video.path}
            onUpload={handlePictureAdd}
            onRemove={handlePictureRemove}
          />
        </div>
        <div>
          <TextField
            className={classes.title}
            required
            label="Títutlo"
            variant="outlined"
            onChange={(e) => setVideo({ ...video, label: e.target.value })}
            value={video.label}
          />
        </div>
      </div>
    </EditMode>
  );
};

export default VideoRead;
