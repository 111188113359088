import { useContext } from "react";
import { Link } from "react-router-dom";
import {
  ErrorContext,
  Message as M,
} from "../../providers/ErrorContextProvider";
import "./Message.scss";

interface MessageProps {
  message: M;
}

const Message = ({
  message: { message, type, permanent, link },
}: MessageProps) => {
  const errorContext = useContext(ErrorContext);

  const getIcon = () => {
    switch (type) {
      case "error":
        return "fa-bug";
      case "success":
        return "fa-check";
      case "warning":
        return "fa-exclamation-triangle";
    }
  };

  return (
    <div>
      <div
        className={type}
        onClick={() => !permanent && errorContext.setMessage()}
      >
        <i className={`fa ${getIcon()}`}></i>
        {message} {link && <Link to={link.to}>{link.text}</Link>}
      </div>
    </div>
  );
};

export default Message;
