import { useEffect, useState } from "react";
import CardEdit from "../components/CardEdit";
import { CardType, CardWithType } from "../../../../shared/types";
import { CardActions } from "../../actions/cards";
import { useNavigate, useParams } from "react-router-dom";
import { PreviewType } from "../components/EditMode";
import { LinearProgress, Box } from "@material-ui/core";

const CardPage = () => {
  const navigate = useNavigate();
  const [card, setCard] = useState<CardWithType>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  useEffect(() => {
    if (params.id) fetchData();
    else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await CardActions.get({
      type: params.type as CardType,
      id: params.id,
    });

    if (response.failed) return;
    setCard(response.data);
    setLoading(false);
  };
  const onSave = async (card: CardWithType) => {
    //TODO: Make loading overlay and use that one for when page is loaded.
    //TODO: Snackbar for confirmation ( modal? )
    setLoading(true);
    let response;
    if (params.id) {
      response = await CardActions.put(card);
    } else {
      response = await CardActions.post(card);
    }

    if (response.failed) return;

    navigate(`/backend/card/${card.type}/${response.id}`);
    setCard(card);
    setLoading(false);
  };
  const onCancel = () => {};
  const onPreview = (card: CardWithType, type: PreviewType) => {};

  return loading ? (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : (
    <CardEdit
      type={params.type as CardType}
      card={card}
      onSave={onSave}
      onCancel={onCancel}
      onPreview={onPreview}
    />
  );
};

export default CardPage;
