import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Workshop } from "../../../shared/types";
import { WorkshopActions } from "../actions/workshops";
import CardDetails from "../components/CardDetails/CardDetails";
import ToggableContent from "../components/ToggableContent/ToggableContent";

const MyWorkshop = () => {
  const [workshop, setWorkshop] = useState<Workshop>();
  const { id } = useParams();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await WorkshopActions.getForOwner({ id: id });

    setWorkshop(response.data);
  };

  return (
    <div>
      <CardDetails
        image={workshop?.image?.path}
        title={workshop?.title}
        description={workshop?.description}
      >
        <ToggableContent
          title="Información taller"
          content={workshop?.text}
          visible={true}
        />
        {workshop?.book && (
          <ToggableContent
            title="Contenido Descargable"
            content={`<a href='${workshop?.book}' download>Descargar</a>`}
            visible={true}
          />
        )}
        {workshop?.courses?.map((c, idx) => {
          const cc = workshop.completeCourses.find((x) => x.id === c);

          if (!cc || (cc.enabled !== undefined && !cc.enabled)) return <></>;

          return (
            <ToggableContent
              key={idx}
              title={cc.title}
              content={cc.text}
              file={cc.file}
            />
          );
        })}
      </CardDetails>
    </div>
  );
};

export default MyWorkshop;
