import { IconButton, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useContext, useState } from "react";
import { Entry } from "../../../../shared/types";
import { DEFAULT_ENTRY, DEFAULT_IMAGE } from "../../helpers/defaults";
import EditMode, { PreviewType } from "./EditMode";
import FileInput from "./FileInput";
import { FileUploader } from "./FileUploader";
import TextEditorWithVideo from "./TextEditorWithVideo";
import { Delete } from "@material-ui/icons";
import { CHARS_TO_REMOVE } from "./WorkshopEdit";
import { ErrorContext } from "../../providers/ErrorContextProvider";
import { Checkbox } from "./Checkbox";

const useStyles = makeStyles((theme) => ({
  entryread: {
    textAlign: "left",
    width: "100%",
    height: "100%",
  },
  title: {
    textAlign: "left",
    width: "50%",
    paddingBottom: "10px",
  },
  description: {
    textAlign: "left",
    width: "90%",
    paddingBottom: "10px",
  },
  image: {
    paddingBottom: "10px",
  },
  input: {
    display: "none",
  },
}));

interface EntryReadProps {
  entry?: Entry;
  onSave: (entry: Entry) => void;
  onCancel: () => void;
  onPreview: (entry: Entry, type: PreviewType) => void;
}
const EntryRead = (props: EntryReadProps) => {
  const classes = useStyles();

  const errorContext = useContext(ErrorContext);

  const [entry, setEntry] = useState<Entry>(props.entry ?? DEFAULT_ENTRY);

  return (
    <EditMode
      onSave={() => props.onSave(entry)}
      onCancel={props.onCancel}
      //onPreview={(type) => props.onPreview(entry, type)}
    >
      <div className={classes.entryread}>
        <div className={classes.image}>
          <FileInput
            type={"image"}
            path={entry.image?.path}
            onUpload={(image: string) =>
              setEntry({ ...entry, image: { path: image } })
            }
            onRemove={() => setEntry({ ...entry, image: DEFAULT_IMAGE })}
          />
        </div>
        <div>
          <TextField
            className={classes.title}
            required
            label="Títutlo"
            variant="outlined"
            onChange={(e) => setEntry({ ...entry, title: e.target.value })}
            value={entry.title}
          />
        </div>
        <div>
          <Checkbox
            label="Habilitado"
            onChange={(state) => setEntry({ ...entry, enabled: state })}
            checked={entry.enabled === undefined || !!entry.enabled}
          />
        </div>
        <div>
          <TextEditorWithVideo
            initialText={entry.text}
            onLateUpdate={(text) => setEntry({ ...entry, text })}
          />
        </div>
        <div>
          {entry.file ? (
            <>
              <Typography variant="h5">Descargable</Typography>
              <p>Nombre de archivo: {entry?.file.slice(CHARS_TO_REMOVE)}</p>
              <a href={entry?.file} download>
                Descargar
              </a>
              <IconButton
                onClick={() => setEntry({ ...entry, file: "" })}
                style={{ padding: "10px" }}
              >
                <Delete></Delete>
              </IconButton>
            </>
          ) : (
            <FileUploader
              path="https://karinazarfino.com/fileUploadScript.php"
              uploadText={"Subir"}
              selectFileText={"Seleccionar archivo"}
              onUpload={(file) => setEntry({ ...entry, file })}
              onError={(m) => errorContext.setMessage(m)}
              title={"Descargable"}
            />
          )}
        </div>
      </div>
    </EditMode>
  );
};

export default EntryRead;
