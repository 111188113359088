import LoginCard from "../components/LoginCard/LoginCard";
import "./Register.css";

const Register = () => {
  return (
    <div className="login">
      <LoginCard hasAccount={false} />
    </div>
  );
};

export default Register;
