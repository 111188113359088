import { Video } from "../../../../shared/types";
import Play from "@material-ui/icons/PlayArrow";
import Add from "@material-ui/icons/Add";
import ListItem from "./ListItem";
import { DEFAULT_VIDEO_THUMB } from "../../helpers/defaults";
import VideoPreview from "./VideoPreview";
import { Pause } from "@material-ui/icons";

interface VideoListItemProps {
  video: Video;
  selected?: boolean;
  onPlay: () => void;
  onAdd: () => void;
  preview: boolean;
}

const VideoListItem = ({
  selected,
  video,
  onAdd,
  onPlay,
  preview,
}: VideoListItemProps) => {
  return (
    <>
      <ListItem
        selected={selected}
        image={video.thumbnail?.path ?? DEFAULT_VIDEO_THUMB}
        key={video.id}
        title={video.label}
        icons={[
          { icon: !preview ? <Play /> : <Pause />, action: onPlay },
          { icon: <Add />, action: onAdd },
        ]}
      />
      {preview && <VideoPreview path={video.path} />}
    </>
  );
};

export default VideoListItem;
