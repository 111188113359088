import "./DeliveryInformation.scss";
import Input from "../Input/Input";
function DeliveryInformation() {
  const _styles = {
    inputStyle: {
      width: "300px",
      margin: "10px 0px",
      padding: "0px 5px",
    },
    labelStyle: {
      display: "flex",
      flexDirection: "column",
    },
    areaCode: {
      width: "50px",
      margin: "10px 5px",
      padding: "0px 5px",
    },
    number: {
      width: "220px",
    },
  };

  return (
    <div className="delivery-container">
      <div className="delivery-title">
        <span>
          <i className="fa fa-home"></i>Entregar a
        </span>
      </div>
      <form action="">
        <div className="delivery-row-01">
          <div className="delivery-row01-container">
            <Input
              labelStyle={_styles.labelStyle}
              type="text"
              inputStyle={_styles.inputStyle}
              label="Email *"
            />
          </div>
          <div className="delivery-row02-container">
            <Input
              labelStyle={_styles.labelStyle}
              type="text"
              inputStyle={_styles.inputStyle}
              label="Nombre *"
            />
            <Input
              labelStyle={_styles.labelStyle}
              type="text"
              inputStyle={_styles.inputStyle}
              label="Apellido *"
            />
          </div>
          <div className="delivery-row03-container">
            <div>
              <span style={{ display: "block" }}>Teléfono</span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>+</span>
                <Input type="text" inputStyle={_styles.areaCode} />

                <Input type="text" inputStyle={_styles.number} />
              </div>
            </div>
            <Input
              type="text"
              inputStyle={_styles.inputStyle}
              label="Documento de identidad (DNI, CI, CC, etc.)
              *"
            />
          </div>
          <div className="delivery-row04-container padding">
            <label className="delivery-checkbox" htmlFor="">
              <input type="checkbox" />
              Entregar a otra persona
            </label>
          </div>
        </div>
        <div className="delivery-title">
          <span>
            <i className="fa fa-home"></i>Dirección de entrega
          </span>
        </div>
        <div className="delivery-row-02">
          <div className="delivery-row01-container">
            <Input
              labelStyle={_styles.labelStyle}
              type="text"
              inputStyle={_styles.inputStyle}
              label="Dirección *"
            />
          </div>
          <div className="delivery-row02-container">
            <Input
              labelStyle={_styles.labelStyle}
              type="text"
              inputStyle={_styles.inputStyle}
              label=" Código Postal *"
            />
            <Input
              labelStyle={_styles.labelStyle}
              type="text"
              inputStyle={_styles.inputStyle}
              label="Localidad *"
            />
          </div>
          <div className="delivery-row03-container">
            <div className="delivery-row03-adress">
              <Input
                labelStyle={_styles.labelStyle}
                type="text"
                inputStyle={_styles.inputStyle}
                label="Provincia *"
              />
            </div>
            <Input
              labelStyle={_styles.labelStyle}
              type="text"
              inputStyle={_styles.inputStyle}
              label="País *"
            />
          </div>
          <div className="delivery-row04-container">
            <div>
              <label className="delivery-checkbox" htmlFor="">
                <input type="checkbox" />
                Establecer como dirección predeterminada
              </label>
              <label htmlFor="">
                Observaciones
                <textarea name="" id="" cols={30} rows={10}></textarea>
              </label>
            </div>
          </div>
        </div>
      </form>
      <p>(*) Indica campos requeridos.</p>

      <div className="delivery-buttons">
        <button className="shiptype-left-btn">
          <i className="fa fa-chevron-left"></i>
          ANTERIOR
        </button>
        <button className="shiptype-right-btn">
          CONFIRMAR
          <i className="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
}

export default DeliveryInformation;
