import { Entry } from "../../../shared/types";
import { remove, post, get, GetResponse, GetAllResponse, put } from "./_base";

const path = "entries";

export class EntriesActions {
  static post = async (data: Entry) => {
    return await post(path, data);
  };
  static get = async (data: Partial<Entry>) => {
    return (await get(path, data, {
      single: true,
    })) as GetResponse<Entry>;
  };
  static getAll = async (data: Partial<Entry>) => {
    return (await get(path, data)) as GetAllResponse<Entry>;
  };
  static put = async (data: Entry) => {
    return await put(path, data);
  };
  static remove = async (id: string) => {
    return await remove(path, id);
  };
}
