import "./DeliveryForm.scss";

function DeliveryForm() {
  return (
    <div>
      <div>
        <span>Entregar a</span>
        <div></div>
      </div>
    </div>
  );
}

export default DeliveryForm;
