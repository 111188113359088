import { useContext, useEffect, useState } from "react";
import "./List.css";
import Card from "../components/Card/Card";
import { WorkshopActions } from "../actions/workshops";
import { CardType, Image, WorkshopType } from "../../../shared/types";
import { AppContext } from "../providers/AppContextProvider";
import { useParams } from "react-router-dom";
import { CardActions } from "../actions/cards";
import { EntriesActions } from "../actions/entries";

interface ListItem {
  id?: string;
  title: string;
  description: string;
  image: Image;
}

const Workshops = () => {
  const [items, setItems] = useState<ListItem[]>([]);

  const appContext = useContext(AppContext);

  const { type } = useParams();

  useEffect(() => {
    setItems([]);
    switch (type) {
      case "workshop":
        getWorkshops("workshop");
        break;
      case "tesa":
        getWorkshops("tesa");
        break;
      case "book":
        getWorkshops("book");
        break;
      case "meditation":
        getGeneric("meditation");
        break;
      case "canalization":
        getGeneric("canalization");
        break;
      case "talks":
        getGeneric("talks");
        break;
      case "interviews":
        getGeneric("interviews");
        break;
      case "radio":
        getGeneric("radio");
        break;
      case "entry":
        getEntries();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const getWorkshops = async (type: WorkshopType) => {
    const response = await WorkshopActions.getAll({
      workshopType: type,
      code: appContext.location.code,
    });
    setItems(response.data);
  };

  const getEntries = async () => {
    const response = await EntriesActions.getAll({});
    setItems(
      response.data.map((entry) => ({ ...entry, description: "" })).reverse()
    );
  };

  const getGeneric = async (type: CardType) => {
    const response = await CardActions.getAll({
      type,
    });
    setItems(response.data.reverse());
  };

  const getUrl = (id: string) => {
    if (type === "workshop" || type === "tesa" || type === "book")
      return `/workshop/${id}`;

    return `/details/${type}/${id}`;
  };

  return (
    <>
      {items?.map(
        (i) =>
          i.id && (
            <Card
              key={i.id}
              title={i.title}
              description={i.description}
              image={i.image?.path}
              url={getUrl(i.id)}
            />
          )
      )}
    </>
  );
};

export default Workshops;
