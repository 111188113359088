import { remove } from "lodash";
import { createContext, useContext, useState } from "react";
import {
  CartItem,
  Ticket,
  Workshop,
  WorkshopType,
} from "../../../shared/types";
import { priceFinal } from "../helpers/functions";
import { ContentContext } from "./ContentContextProvider";
import { ErrorContext } from "./ErrorContextProvider";

export interface InCartItem extends Workshop {
  quantity: number;
}

interface ICartContext {
  addItem: (id: string, type: WorkshopType, fixedQuantity?: number) => void;
  removeItem: (item: CartItem) => void;
  getCartItems: () => InCartItem[];
  setTicket: (ticketId: Ticket) => void;
  getTicket: () => Ticket | null;
  calculateTotal: (cartItems: InCartItem[]) => number;
  clearCart: () => void;
}

const DEFAULT: ICartContext = {
  addItem: () => undefined,
  removeItem: () => undefined,
  getCartItems: () => [],
  setTicket: () => undefined,
  getTicket: () => null,
  calculateTotal: () => 0,
  clearCart: () => undefined,
};

export const CartContext = createContext<ICartContext>(DEFAULT);

const localStorage = window.localStorage;

interface CartContextProviderProps {
  children?: React.ReactNode;
}

const STORAGE_KEY_CART = "cart";
const STORAGE_KEY_TICKET_ID = "ticket";

export const CartContextProvider = (props: CartContextProviderProps) => {
  const contentContext = useContext(ContentContext);
  const errorContext = useContext(ErrorContext);

  const addItem = (id: string, type: WorkshopType, fixedQuantity?: number) => {
    let cartItems = getCartFromLocalStorage();

    const item = contentContext.workshops.find((w) => w.id === id);

    if (!item) {
      errorContext.setMessage({
        message: "No se pudo agregar item al carro",
        type: "error",
      });
      return;
    }

    const inCart = cartItems.find((ci) => ci.id === id);

    if (inCart) {
      if (fixedQuantity !== undefined) inCart.quantity = fixedQuantity;
      else inCart.quantity++;
    } else if (fixedQuantity && fixedQuantity > 0)
      cartItems.push({ ...item, quantity: fixedQuantity });

    saveCartToLocalStorage(cartItems);

    errorContext.setMessage({
      message: `${item.title} agregado. Ve al carrito de compra para abonar tu producto.`,
      link: { to: "/cart", text: "Ir" },
      type: "success",
      permanent: true,
    });
  };

  const saveCartToLocalStorage = (cartItems: InCartItem[]) => {
    localStorage.setItem(STORAGE_KEY_CART, JSON.stringify(cartItems));
  };

  const removeItem = (item: CartItem) => {
    removeEntirely(item);
  };

  const removeEntirely = (item: CartItem) => {
    const cartItems = getCartFromLocalStorage();

    const cartItem = contentContext.workshops.find((w) => w.id === item.id);

    if (!cartItem) {
      errorContext.setMessage({
        message: `Item no encontrado`,
        link: { to: "/cart", text: "Ver" },
        type: "error",
      });
      return;
    }

    remove(cartItems, (i) => cartItem?.id === i.id);

    saveCartToLocalStorage(cartItems);

    errorContext.setMessage({
      message: `${cartItem.title} quitado del carrito`,
      link: { to: "/cart", text: "Ver" },
      type: "warning",
    });
  };

  const setTicket = (ticket: Ticket) => {
    localStorage.setItem(STORAGE_KEY_TICKET_ID, JSON.stringify(ticket));
  };

  const getTicket = () => {
    const lst = localStorage.getItem(STORAGE_KEY_TICKET_ID);

    if (lst) return JSON.parse(lst) as Ticket;
    else return null;
  };

  const calculateTotal = (cartItems: InCartItem[]) => {
    const total = cartItems.reduce((p, c) => p + priceFinal(c.price), 0);

    return total;
  };

  const getCartFromLocalStorage = () => {
    const cartData = localStorage.getItem(STORAGE_KEY_CART);
    const cartItems = cartData ? JSON.parse(cartData) : [];
    return cartItems as InCartItem[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const clearCart = () => {
    localStorage.removeItem(STORAGE_KEY_CART);
  };

  const [cart] = useState<ICartContext>({
    addItem,
    removeItem,
    getCartItems: getCartFromLocalStorage,
    setTicket,
    getTicket,
    calculateTotal,
    clearCart,
  });

  const { children } = props;

  return <CartContext.Provider value={cart}>{children}</CartContext.Provider>;
};
