import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import ExitIcon from "@material-ui/icons/ExitToApp";
import { CardType } from "../../../../shared/types";
import Toolbar from "@material-ui/core/Toolbar";
import { useNavigate } from "react-router-dom";
import { getTypeNamePlural } from "../../helpers/functions";

const drawerWidth = 240;

const useStyles = makeStyles({
  list: {
    width: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  icon: { width: "45px", textAlign: "center" },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
});

interface DrawerProps {
  open: boolean;
}

const cardTypes: CardType[] = [
  "meditation",
  "canalization",
  "talks",
  "radio",
  "interviews",
];

interface BackendNavigationItem {
  name?: string;
  route?: string;
  icon?: JSX.Element;
  divider?: boolean;
}

const navigationItems: BackendNavigationItem[] = [
  {
    name: "Inicio",
    route: "/backend/",
    icon: <InboxIcon />,
  },
  {
    name: "Países",
    route: "/backend/countries",
    icon: <MailIcon />,
  },
  {
    name: "Talleres",
    route: "/backend/workshops/workshop",
    icon: <MailIcon />,
  },
  {
    name: "Tesa",
    route: "/backend/workshops/tesa",
    icon: <MailIcon />,
  },
  {
    name: "Libros",
    route: "/backend/workshops/book",
    icon: <MailIcon />,
  },
  {
    name: "Videos",
    route: "/backend/videos",
    icon: <MailIcon />,
  },
  {
    name: "Usuarios",
    route: "/backend/users",
    icon: <MailIcon />,
  },
  {
    name: "Sobre Mi",
    route: "/backend/about",
    icon: <MailIcon />,
  },
  ...cardTypes.map((c) => ({
    name: getTypeNamePlural(c),
    route: `/backend/cards/${c}`,
    icon: <MailIcon />,
  })),
  {
    name: "Fotos",
    route: "/backend/photos",
    icon: <MailIcon />,
  },
  {
    name: "Blog",
    route: "/backend/blog",
    icon: <MailIcon />,
  },
  {
    name: "Transacciones",
    route: "/backend/tickets",
    icon: <MailIcon />,
  },
  {
    divider: true,
    route: "/",
  },
  {
    name: "Pagina Principal",
    route: "/",
    icon: <ExitIcon />,
  },
];

const TemporaryDrawer = (props: DrawerProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const list = () => (
    <div className={classes.list} role="presentation">
      <Toolbar />
      <List>
        {navigationItems.map((i, index) =>
          i.divider ? (
            <Divider key={index} />
          ) : (
            <ListItem
              button
              key={i.name}
              onClick={() => navigate(i.route ?? "")}
            >
              <ListItemIcon className={classes.icon}>{i.icon}</ListItemIcon>
              <ListItemText primary={i.name} />
            </ListItem>
          )
        )}
      </List>
    </div>
  );

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      classes={{
        paper: classes.drawerPaper,
      }}
      open={props.open}
    >
      <div className={classes.drawerContainer}>{list()}</div>
    </Drawer>
  );
};

export default TemporaryDrawer;
