import { List, Typography } from "@material-ui/core";
import Button from "./Button";
import { Delete } from "@material-ui/icons";
import { useState } from "react";
import { Purchase, Workshop } from "../../../../shared/types";
import { DEFAULT_PURCHASE } from "../../helpers/defaults";
import ListItem from "./ListItem";
import Modal from "./Modal";
import SelectorDropdown from "./SelectorDropdown";
import { dateToString } from "../../helpers/functions";

interface PurchaseListProps {
  workshops: Workshop[];
  purchases: Purchase[];
  onChange: (p: Purchase[]) => void;
}
const PurchaseList = ({
  workshops,
  purchases,
  onChange,
}: PurchaseListProps) => {
  const [newp, setNew] = useState<Purchase>();
  const [edit, setEdit] = useState<Purchase>();

  const handleRemove = (idx: number) => {
    purchases.splice(idx, 1);
    onChange(purchases);
  };

  return (
    <>
      <Typography variant="h5">Compras</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          setNew({ ...DEFAULT_PURCHASE, id: workshops[0].id as string })
        }
        style={{ padding: "10px" }}
      >
        Agregar
      </Button>
      {newp && (
        <Modal
          onSave={() => {
            onChange([...purchases, newp]);
            setNew(undefined);
          }}
          onCancel={() => setNew(undefined)}
        >
          <SelectorDropdown
            label={"Taller"}
            onChange={(v) =>
              setNew({
                ...newp,
                id: v,
              })
            }
            values={workshops.map((w) => ({
              text: `${w.workshopType}: ${w.title}`,
              value: w.id ?? "",
            }))}
          />
        </Modal>
      )}
      {edit && (
        <Modal
          onSave={() => {
            onChange([...purchases, edit]);
            setEdit(undefined);
          }}
          onCancel={() => setEdit(undefined)}
        ></Modal>
      )}
      <List>
        {purchases &&
          purchases.map((p, idx) => {
            const workshop = workshops.find((w) => w.id === p.id);

            return (
              <ListItem
                image={workshop?.image?.path}
                key={idx}
                title={workshop?.title}
                description={`${
                  p.ts ? `Fecha:${dateToString(new Date(p.ts))}` : ""
                } ${
                  p.expiration && p.expiration > 0
                    ? `Vencimiento:${dateToString(new Date(p.expiration))}`
                    : ""
                }`}
                icons={[{ icon: <Delete />, action: () => handleRemove(idx) }]}
              />
            );
          })}
      </List>
    </>
  );
};

export default PurchaseList;
