import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { CardType, CardWithType } from "../../../../shared/types";
import { DEFAULT_CARD, DEFAULT_IMAGE } from "../../helpers/defaults";
import EditMode, { PreviewType } from "./EditMode";
import FileInput from "./FileInput";
import TextEditorWithVideo from "./TextEditorWithVideo";

const useStyles = makeStyles((theme) => ({
  cardread: {
    textAlign: "left",
    width: "100%",
  },
  title: {
    textAlign: "left",
    width: "50%",
    paddingBottom: "10px",
  },
  description: {
    textAlign: "left",
    width: "90%",
    paddingBottom: "10px",
  },
  image: {
    paddingBottom: "10px",
  },
  input: {
    display: "none",
  },
}));

interface CardWithTypeReadProps {
  type: CardType;
  card?: CardWithType;
  onSave: (card: CardWithType) => void;
  onCancel: () => void;
  onPreview: (card: CardWithType, type: PreviewType) => void;
}
const CardWithTypeRead = (props: CardWithTypeReadProps) => {
  const classes = useStyles();

  const [card, setCard] = useState<CardWithType>(
    props.card ?? { ...DEFAULT_CARD, type: props.type }
  );

  const getPageName = () => {
    switch (card.type) {
      case "canalization":
        return "Canalización";
      case "meditation":
        return "Meditación";
      case "interviews":
        return "Entrevista";
      case "radio":
        return "Show radio";
      case "talks":
        return "Hablando con Karina";
    }
  };

  return (
    <EditMode
      onSave={() => props.onSave(card)}
      onCancel={() => props.onCancel}
      //onPreview={(type) => props.onPreview(card, type)}
    >
      <div className={classes.cardread}>
        <div className={classes.title}>
          <Typography variant="h4">{getPageName()}</Typography>
        </div>
        <div className={classes.image}>
          <FileInput
            type={"image"}
            path={card.image?.path}
            onUpload={(image: string) =>
              setCard({ ...card, image: { path: image } })
            }
            onRemove={() => {
              return setCard({ ...card, image: DEFAULT_IMAGE });
            }}
          />
        </div>
        <div>
          <TextField
            className={classes.title}
            required
            label="Títutlo"
            variant="outlined"
            onChange={(e) => setCard({ ...card, title: e.target.value })}
            value={card.title}
          />
        </div>
        <div>
          <TextField
            className={classes.description}
            label={"Descripción corta"}
            variant="outlined"
            onChange={(e) => setCard({ ...card, description: e.target.value })}
            value={card.description}
          />
        </div>
        <div>
          <TextEditorWithVideo
            initialText={card.text}
            onLateUpdate={(text) => setCard({ ...card, text })}
          />
        </div>
      </div>
    </EditMode>
  );
};

export default CardWithTypeRead;
