import { User, PasswordChange } from "../../../shared/types";
import { post, get, GetResponse, GetAllResponse, put, remove } from "./_base";

const path = "users";

export class UserActions {
  static post = async (data: User) => {
    return await post(path, data);
  };
  static resetUserPassword = async (id: string) => {
    return await post(`${path}/resetPasswordFor`, { id });
  };

  static changePassword = async (change: PasswordChange) => {
    return await post(`${path}/changePassword`, change);
  };
  static get = async (data: Partial<User>) => {
    return (await get(path, data, {
      single: true,
    })) as GetResponse<User>;
  };
  static getAll = async (data: Partial<User>) => {
    return (await get(path, data)) as GetAllResponse<User>;
  };
  static put = async (data: User) => {
    return await put(path, data);
  };
  static remove = async (id: string) => {
    return await remove(path, id);
  };
}
