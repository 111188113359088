import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useEffect, useState } from "react";
import { Course } from "../../../../shared/types";
import CourseListItem from "./CourseListItem";
import CourseListItemAdd from "./CourseListItemAdd";
import { List, Typography } from "@material-ui/core";
import { CourseActions } from "../../actions/courses";
import Button from "./Button";
import { DEFAULT_COURSE } from "../../helpers/defaults";

interface CoursesProps {
  courses: string[];
  onChange: (courses: string[]) => void;
  onChangeCourses: (courses: string[]) => void;
}

const Courses = (props: CoursesProps) => {
  const [addNew, setAddNew] = useState<boolean>(false);
  const [fullCourses, setFullCourses] = useState<Course[]>([]);
  const [editCourse, setEditCourse] = useState<Course>();

  const handleRemoveCourse = (idx: number) => {
    const ncourses = [...props.courses];
    ncourses.splice(idx, 1);
    props.onChange(ncourses);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await CourseActions.getAll({});
    setFullCourses(response.data);
  };

  const handleItemDropped = (from: number, to: number) => {
    console.log("handleItemDropped", from, to);

    const movingTreament = props.courses[from];

    const temp = props.courses;
    temp.splice(from, 1);

    let half1: string[] = [];
    let half2 = temp;

    if (to !== 0) {
      half1 = temp.slice(0, to);
      half2 = temp.slice(to);
    }

    const newCourses = [...half1, movingTreament, ...half2];
    props.onChangeCourses(newCourses);
  };

  return (
    <>
      <Typography variant="h5">Cursos</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setAddNew(true)}
        style={{ padding: "10px" }}
      >
        Agregar
      </Button>{" "}
      <DndProvider backend={HTML5Backend}>
        <List>
          {(addNew || editCourse) && (
            <CourseListItemAdd
              disabled={props.courses}
              courses={fullCourses}
              course={editCourse ?? DEFAULT_COURSE}
              onSave={() => {
                setAddNew(false);
                setEditCourse(undefined);
              }}
              onAdd={(c: Course) => {
                if (!c.id) return;

                if (!props.courses.some((co) => co === c.id))
                  props.onChange([...props.courses, c.id]);

                setFullCourses([
                  ...fullCourses.filter((x) => x.id !== c.id),
                  c,
                ]);
              }}
            />
          )}
          {props.courses
            .map((cid) => fullCourses.find((c) => c.id === cid))
            .map(
              (c, idx) =>
                c && (
                  <CourseListItem
                    key={`${c.id}-${c.title}`}
                    index={idx}
                    course={c}
                    onRemove={() => handleRemoveCourse(idx)}
                    onEdit={() => setEditCourse(c)}
                    onItemDropped={handleItemDropped}
                  />
                )
            )}
        </List>
      </DndProvider>
    </>
  );
};

export default Courses;
