import { Component } from "react";
import { isDevEnv } from "../../helpers/functions";
import { BackwardIcon, ForwardIcon, PauseIcon, PlayIcon } from "./Icons";

interface PropTypes {
  src: string;
}

interface StateTypes {
  paused: boolean;
  time: number;
  duration: number;
  cvideoWidth: number;
  cvideoHeight: number;
}

const CANVAS_WIDTH = 1280;
const CANVAS_HEIGHT = 720;

export class VideoPlayer extends Component<PropTypes, StateTypes> {
  canvas: HTMLCanvasElement | null = null;
  ctx: CanvasRenderingContext2D | null = null;
  video: HTMLVideoElement = document.createElement("video");
  initiealized = false;
  state = {
    paused: true,
    time: 0,
    duration: 0,
    cvideoWidth: 0,
    cvideoHeight: 0,
  };

  draw = () => {
    if (this.initiealized) return;
    if (!this.canvas) return;

    this.initiealized = true;

    this.ctx = this.canvas.getContext("2d");

    this.video.preload = "metadata";
    this.video.src = isDevEnv()
      ? `https://www.karinazarfino.com${this.props.src}`
      : this.props.src;

    this.video.addEventListener("play", this.handlePlay, false);
    this.video.addEventListener("loadeddata", this.handleOnLoad, false);

    this.canvas.addEventListener("click", this.handleClick, false);

    this.setState({
      paused: this.video.paused,
      time: this.video.currentTime,
      duration: this.video.duration,
    });
  };

  componentWillUnmount() {
    if (this.video) {
      this.video.pause();
      this.video.removeEventListener("play", this.handlePlay);
      this.video.removeEventListener("loadeddata", this.handleOnLoad);
      this.video.remove();
      console.log("bye video");
    }
    if (this.canvas) {
      console.log("bye canvas");
      this.canvas.removeEventListener("click", this.handleClick, false);
    }
  }

  calculateSize = (w: number, h: number, maxW: number, maxH: number): any => {
    let cvideoWidth = w;
    let cvideoHeight = h;

    const factor = maxW / w;
    cvideoWidth = w * factor;
    cvideoHeight = h * factor;

    if (cvideoHeight > maxH) {
      const factorH = maxH / h;
      cvideoWidth = w * factorH;
      cvideoHeight = h * factorH;
    }

    return { cvideoWidth, cvideoHeight };
  };

  handleOnLoad = () => {
    if (!this.canvas) return;
    const { cvideoWidth, cvideoHeight } = this.calculateSize(
      this.video.videoWidth,
      this.video.videoHeight,
      this.canvas?.width,
      this.canvas?.height
    );

    if (this.video.readyState >= 2)
      this.setState({
        time: this.video.currentTime,
        duration: this.video.duration,
        cvideoWidth,
        cvideoHeight,
      });
  };

  handleClick = () => {
    if (!this.video.paused) {
      this.video.pause();
    } else {
      this.video.play();
    }
    this.setState({ paused: this.video.paused });
  };

  handlePlay = () => {
    this.play();
  };

  play = () => {
    if (!this.video || !this.canvas) return;
    if (this.state.paused !== this.video.paused) this.video.pause();
    this.ctx?.drawImage(
      this.video,
      this.canvas?.width / 2 - this.state.cvideoWidth / 2,
      0,
      this.state.cvideoWidth,
      this.state.cvideoHeight
    );
    requestAnimationFrame(this.play);
    this.setState({
      time: this.video.currentTime,
      duration: this.video.duration,
    });
  };

  render() {
    const minutes = Math.floor(this.state.time / 60);
    let seconds: any = Math.floor(this.state.time % 60);
    if (seconds < 10) seconds = "0" + seconds;

    const totalMinutes = Math.floor(this.state.duration / 60);
    const totalSeconds = Math.floor(this.state.duration % 60);
    return (
      <div style={{ width: "100%", height: "auto" }} id={"scrollBox"}>
        <canvas
          width={CANVAS_WIDTH}
          height={CANVAS_HEIGHT}
          ref={(ref: HTMLCanvasElement) => {
            this.canvas = ref;
            this.draw();
          }}
          style={{ width: "100%", height: "auto" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div style={{ width: 20, height: 20 }} onClick={this.handleClick}>
            {this.state.paused ? PlayIcon : PauseIcon}
          </div>
          <div
            style={{ width: 20, height: 20 }}
            onClick={() => {
              this.video.currentTime = this.video.currentTime - 5;
            }}
          >
            {BackwardIcon}
          </div>
          <div
            style={{ width: 20, height: 20 }}
            onClick={() => {
              this.video.currentTime = this.video.currentTime + 5;
            }}
          >
            {ForwardIcon}
          </div>
          <div style={{ paddingLeft: "10px" }}>{` ${minutes}:${seconds}`}</div>

          <div>
            {!isNaN(totalMinutes)
              ? `/${totalMinutes}:${
                  totalSeconds < 10 ? "0" + totalSeconds : totalSeconds
                }`
              : "/Cargando video..."}
          </div>
        </div>
      </div>
    );
  }
}
