import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../providers/CartContextProvider";

const ClearCart = () => {
  const cartContext = useContext(CartContext);

  const navigate = useNavigate();

  useEffect(() => {
    cartContext.clearCart();
    navigate("/myworkshops");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default ClearCart;
