import { useEffect, useState } from "react";
import { Editor } from "tinymce";
import Modal from "./Modal";
import TextEditor, { TextEditorProps } from "./TextEditor";
import VideoListItem from "./VideoListItem";
import { Divider, List, makeStyles } from "@material-ui/core";
import { Video } from "../../../../shared/types";
import FileInput from "./FileInput";
import { VideoActions } from "../../actions/videos";
import { DEFAULT_IMAGE } from "../../helpers/defaults";

interface TextEditorWithVideoProps extends TextEditorProps {
  initialText?: string;
  onLateUpdate?: (content: string) => void;
}

const useStyles = makeStyles({
  textContainer: {
    height: "400px",
    paddingBottom: "20px",
  },
  videoTitle: {
    textAlign: "left",
    overflowX: "hidden",
  },
  scroll: {
    width: "90%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    paddingRight: "20px",
  },
});

const TextEditorWithVideo = ({
  initialText,
  onLateUpdate,
}: TextEditorWithVideoProps) => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [editor, setEditor] = useState<Editor>();
  const [videos, setVideos] = useState<Video[]>([]);
  const [videoPreview, setVideoPreview] = useState<string>();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await VideoActions.getAll({});
    setVideos(response.data);
  };

  const handleInsertVideo = (videoPath: string) => {
    editor?.insertContent(`<video controls controlsList="nodownload" width="320" height="240">
    <source src="${videoPath}" type="video/mp4">
    Navegador incompatible
  </video>`);
  };

  return (
    <>
      <TextEditor
        className={classes.textContainer}
        initialText={initialText}
        onLateUpdate={onLateUpdate}
        onUploadClick={() => {
          setModalOpen(true);
          setVideoPreview(undefined);
        }}
        onTakeTheEditor={(e: Editor) => {
          setEditor(e);
        }}
      />
      {modalOpen && (
        <Modal onCancel={() => setModalOpen(false)}>
          <FileInput
            type={"video"}
            onUpload={async (videoPath) => {
              setVideos([
                ...videos,
                {
                  path: videoPath,
                  provider: "hosted",
                  thumbnail: DEFAULT_IMAGE,
                },
              ]);
              await VideoActions.post({
                path: videoPath,
                provider: "hosted",
                thumbnail: DEFAULT_IMAGE,
              });
              handleInsertVideo(videoPath);
              setModalOpen(false);
            }}
            onRemove={() => {}}
          />
          <List>
            {videos.map((v, idx) => (
              <div key={v.id}>
                <VideoListItem
                  selected={v.id === videoPreview}
                  video={v}
                  onAdd={() => {
                    handleInsertVideo(v.path);
                    setModalOpen(false);
                  }}
                  onPlay={() => {
                    v.id === videoPreview
                      ? setVideoPreview(undefined)
                      : setVideoPreview(v.id);
                  }}
                  preview={videoPreview === v.id}
                />
                {videos.length !== idx + 1 && <Divider />}
              </div>
            ))}
          </List>
        </Modal>
      )}
    </>
  );
};

export default TextEditorWithVideo;
