import { NavMenuItem } from "../../navigationMenu";
import NavItem from "../NavBar/NavItem";
import "./Dropdown.scss";

interface DropdownProps {
  items: NavMenuItem[];
  dropdown: boolean;
  depthLevel: number;
}

function Dropdown(props: DropdownProps) {
  let { items, dropdown, depthLevel } = props;
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-btn-submenu" : "";

  return (
    <ul
      className={`dropdown-ul ${dropdownClass} ${dropdown ? "show" : "hide"}`}
    >
      {items.map((item: NavMenuItem, index: number) => (
        <NavItem item={item} key={index} depthLevel={depthLevel} />
      ))}
    </ul>
  );
}

export default Dropdown;
