import { ImageList, ImageListItem } from "@material-ui/core";
import Button from "./Button";
import { useContext } from "react";
import { ErrorContext } from "../../providers/ErrorContextProvider";
import { FileUploader } from "./FileUploader";
import VideoPreview from "./VideoPreview";

type FileInputType = "video" | "image";

interface FileInputProps {
  type: FileInputType;
  path?: string;
  label?: string;
  onUpload: (image: string) => void;
  onRemove: () => void;
}

const FileInput = ({
  path,
  label,
  type,
  onUpload,
  onRemove,
}: FileInputProps) => {
  const errorContext = useContext(ErrorContext);

  return (
    <>
      {path ? (
        <>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onRemove()}
              style={{ padding: "10px" }}
            >
              Cambiar
            </Button>
          </div>
          <div>
            {type === "video" ? (
              <VideoPreview path={path} />
            ) : (
              <ImageList>
                <ImageListItem>
                  <img src={path} alt={label} />
                </ImageListItem>
              </ImageList>
            )}
          </div>
        </>
      ) : (
        <FileUploader
          uploadText={"Subir"}
          title={type === "video" ? "Video" : undefined}
          selectFileText={
            type === "video" ? "Seleccionar video" : "Seleccionar imágen"
          }
          onUpload={onUpload}
          onError={(m) => {
            errorContext.setMessage(m);
          }}
        />
      )}
    </>
  );
};

export default FileInput;
