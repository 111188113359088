import React from "react";
import Gallery from "../components/Gallery/Gallery";
function Videos() {
  const thumbnails = [
    "https://img.youtube.com/vi/yJ_v5UHu8mE/0.jpg",

    "https://img.youtube.com/vi/bAEkmFB_-bY/0.jpg",

    "https://img.youtube.com/vi/wGMoDL4HMko/0.jpg",
  ];

  const videos = [
    "https://www.youtube.com/embed/gTQEoHEHhtI",
    "https://www.youtube.com/embed/bAEkmFB_-bY",
    "https://www.youtube.com/embed/EgVpdAtfCvo",
  ];
  return (
    <div>
      <h1>videos</h1>
      <Gallery
        images={thumbnails}
        type="video"
        videos={videos}
        title="Algo random"
      />
    </div>
  );
}

export default Videos;
