import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 80,
      width: "100%",
      textAlign: "left",
      contentAlign: "left",
    },
  })
);

interface Selector<T extends string | number> {
  value: T;
  text: string;
}

interface SelectorDropdownProps<T extends string | number> {
  label: string;
  values: Selector<T>[];
  value?: T;
  onChange: (value: T) => void;
}

function SelectorDropdown<T extends string | number>({
  label,
  values,
  onChange,
  value,
}: SelectorDropdownProps<T>) {
  const classes = useStyles();

  const [selected, setSelected] = useState<T>(value ?? (values[0].value as T));

  return (
    <>
      {values.length > 0 && (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            {label}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            value={selected}
            onChange={(e) => {
              const v = e.target.value as unknown as T;
              setSelected(v);
              onChange(v);
            }}
            label={label}
          >
            {values.map((v, idx) => (
              <MenuItem key={idx} value={v.value}>
                {v.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}

export default SelectorDropdown;
