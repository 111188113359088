import { createContext, useContext, useEffect, useState } from "react";
import { Workshop } from "../../../shared/types";
import { WorkshopActions } from "../actions/workshops";
import { AppContext } from "./AppContextProvider";

interface Content {
  workshops: Workshop[];
}

const DEFAULT: Content = {
  workshops: [],
};

export const ContentContext = createContext<Content>(DEFAULT);

interface ContentContextProviderProps {
  children?: React.ReactNode;
}

export const ContentContextProvider = (props: ContentContextProviderProps) => {
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  const [content, setContent] = useState<Content>({
    ...DEFAULT,
  });

  const fetchData = async () => {
    const response = await WorkshopActions.getAll({
      code: appContext?.location?.code,
    });
    setContent({ ...content, workshops: response?.data });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { children } = props;

  return (
    <ContentContext.Provider value={content}>
      {!loading && children}
    </ContentContext.Provider>
  );
};
