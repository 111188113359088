import { useContext, useEffect, useState } from "react";
import NewsEdit from "../components/NewsEdit";
import { CountryCode, News } from "../../../../shared/types";
import { SectionActions } from "../../actions/sections";
import { PreviewType } from "../components/EditMode";
import { LinearProgress, Box } from "@material-ui/core";
import SelectorDropdown from "../components/SelectorDropdown";
import { AppContext } from "../../providers/AppContextProvider";
import { DEFAULT_NEWS } from "../../helpers/defaults";

const NewsPage = () => {
  const appContext = useContext(AppContext);

  const [news, setNews] = useState<News>();
  const [loading, setLoading] = useState(true);
  const [countryCode, setCountryCode] = useState<CountryCode>(
    appContext.countries[0].code
  );

  useEffect(() => {
    setNews(undefined);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const fetchData = async () => {
    const response = await SectionActions.get<News>(
      {
        sectionName: "News",
        country: countryCode,
      },
      { noerror: true }
    );
    if (response.failed) setNews({ ...DEFAULT_NEWS, country: countryCode });
    else setNews(response.data);
    setLoading(false);
  };
  const onSave = async (news: News) => {
    //TODO: Make loading overlay and use that one for when page is loaded.
    //TODO: Snackbar for confirmation ( modal? )
    setLoading(true);
    if (news._id) await SectionActions.put(news);
    else await SectionActions.post(news);

    setNews(news);
    setLoading(false);
  };
  const onCancel = () => {};
  const onPreview = (news: News, type: PreviewType) => {};

  return (
    <>
      <SelectorDropdown
        label={"País"}
        onChange={(v) => setCountryCode(v as CountryCode)}
        values={[
          ...appContext.countries.map((c) => ({
            text: c.code,
            value: c.code,
          })),
        ]}
      />
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        news && (
          <NewsEdit
            news={news}
            onSave={onSave}
            onCancel={onCancel}
            onPreview={onPreview}
          />
        )
      )}
    </>
  );
};

export default NewsPage;
