import { useEffect, useState } from "react";
import {
  List,
  LinearProgress,
  Typography,
  TextField,
  Divider,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import ListItem from "../components/ListItem";
import { Ticket, User, Workshop } from "../../../../shared/types";
import { TicketActions } from "../../actions/ticket";
import { UserActions } from "../../actions/user";
import { WorkshopActions } from "../../actions/workshops";
import { dateToString } from "../../helpers/functions";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const ICON_SIZE = "50px";

interface TicketInformation {
  ticket: Ticket;
  user?: User;
  items: Workshop[];
}

const TicketsPage = () => {
  const [tickets, setTickets] = useState<TicketInformation[]>([]);
  const [workshops, setWorkshops] = useState<Workshop[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<string>();
  const [details, setDetails] = useState<string>();
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const tdata = await TicketActions.getAll({});

    if (tdata.failed) return;

    const udata = await UserActions.getAll({});

    if (udata.failed) return;

    const wdata = await WorkshopActions.getAllForBack({});

    if (wdata.failed) return;

    setWorkshops(wdata.data);

    setLoading(false);

    const ticketInformation = tdata.data.map((t) => {
      const u = udata.data.find((u) => u.id === t.userId);

      const ws: Workshop[] = wdata.data.filter((w) =>
        t.cart.find((ci) => ci.id === w.id)
      );

      const ti: TicketInformation = {
        ticket: t,
        user: u,
        items: ws,
      };

      return ti;
    });

    setTickets(ticketInformation);
  };

  const filterTickets = (u: TicketInformation) => {
    if (!filter) return true;

    if (
      u.ticket.id?.includes(filter) ||
      u.user?.id?.includes(filter) ||
      u.user?.firstname?.includes(filter) ||
      u.user?.lastname?.includes(filter) ||
      u.user?.email?.includes(filter) ||
      u.user?.uid?.includes(filter) ||
      u.user?.phoneNumber?.includes(filter) ||
      u.user?.mobileNumber?.includes(filter)
    )
      return true;

    return false;
  };

  return (
    <>
      <Typography variant="h2">Transacciones</Typography>
      <div>
        <TextField
          label="Búsqueda"
          variant="outlined"
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
        />
        <Search style={{ width: ICON_SIZE, height: ICON_SIZE }} />
      </div>
      {loading ? (
        <LinearProgress />
      ) : (
        <List>
          {tickets
            .filter((ti) => filterTickets(ti))
            .reverse()
            .map((ti) => (
              <ListItem
                key={ti.ticket.id}
                title={`Codigo transaccion: ${ti.ticket.id}`}
                description={`Fecha: ${dateToString(
                  new Date(ti.ticket.created)
                )} Usuario: ${
                  ti.user === undefined ? "No encontrado" : `${ti.user?.email}`
                }`}
                icons={[
                  {
                    icon:
                      details !== ti.ticket.id ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      ),
                    action: () =>
                      setDetails(ti.ticket.id === details ? "" : ti.ticket.id),
                  },
                ]}
              >
                {details === ti.ticket.id ? (
                  <div style={{ paddingRight: "50px" }}>
                    <Divider />
                    <br />
                    <b>Creada:</b> {dateToString(new Date(ti.ticket.created))}
                    <br />
                    <b>Concretada: </b>
                    {ti.ticket.concretedDate
                      ? `${dateToString(
                          new Date(ti.ticket.concretedDate)
                        )} OP: ${ti.ticket.concreted}`
                      : "N/A"}
                    <br />
                    <b>Cancelada: </b>
                    {ti.ticket.cancelled
                      ? `${dateToString(new Date(ti.ticket.cancelled))} por ${
                          ti.ticket.cancelledBy
                        }`
                      : "N/A"}
                    <br />
                    <b>Metodo de pago: </b>
                    {ti.ticket.method
                      ? ti.ticket.method.toString()
                      : "MercadoPago"}
                    <br />
                    {ti.ticket.address ? (
                      <>
                        <b>Direccion de entrega: </b>
                        {JSON.stringify(ti.ticket.address)}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    <b>Total: </b>
                    {ti.ticket.price}
                    <br />
                    <b>Items:</b>
                    <br /> <Divider />
                    {ti.ticket.cart.map((item) => (
                      <>
                        Id: {item.id}
                        <br />
                        Nombre:{" "}
                        {workshops?.find((w) => w.id === item.id)?.title}
                        <br />
                        Categoria: {item.type}
                        <br />
                        <Divider />
                      </>
                    ))}
                    <br />
                    <Divider />
                  </div>
                ) : undefined}
              </ListItem>
            ))}
        </List>
      )}
    </>
  );
};

export default TicketsPage;
