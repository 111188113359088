import axios from "axios";

export class PhotoActions {
  static get = async () => {
    const promise = new Promise<string[]>((resolve) => {
      axios
        .get("/imageList.php")
        .then((res: any) => {
          resolve(res.data.files);
        })
        .catch((err: any) => resolve([]));
    });

    return promise;
  };
}
