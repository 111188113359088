import "./SubDrawer.scss";
import DrawerItem from "./DrawerItem";
import { NavMenuItem } from "../../navigationMenu";

interface SubDrawerProps {
  depthLevel?: any;
  submenus: NavMenuItem[];
  setOpenItem?: any;
  openItem?: any;
}
function SubDrawer(props: SubDrawerProps) {
  const { submenus, openItem, setOpenItem } = props;
  let { depthLevel } = props;
  const openDrawer = openItem ? "subdrawer-on" : "subdrawer-off";

  depthLevel = depthLevel + 1;
  return (
    <>
      <div className={`subdrawer-container ${openDrawer} `}>
        <div className="subdrawer-back-button">
          <span onClick={setOpenItem}>Volver</span>
        </div>
        {submenus.map((menu: NavMenuItem, key: any) => {
          return (
            <DrawerItem key={key} navigation={menu} depthLevel={depthLevel} />
          );
        })}
      </div>
    </>
  );
}

export default SubDrawer;
