import { Course } from "../../../../shared/types";
import CourseEdit from "./CourseEdit";
import { CourseActions } from "../../actions/courses";

interface CourseEditProps {
  disabled: string[];
  courses: Course[];
  course: Course;
  onSave: () => void;
  onAdd: (c: Course) => void;
}

const CourseListItemAdd = ({
  courses,
  disabled,
  course,
  onSave,
  onAdd,
}: CourseEditProps) => {
  return (
    <CourseEdit
      course={course}
      onSave={async (c) => {
        if (c.id) await CourseActions.put(c);
        else {
          const response = await CourseActions.post(c);
          c = { ...c, id: response.id };
        }

        if (!c.id) return;
        onAdd(c);
        onSave();
      }}
      onCancel={onSave}
      onPreview={() => {}}
    />
  );
};

export default CourseListItemAdd;
