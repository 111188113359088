import { Component } from "react";
import "./Footer.scss";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-contact">
          <ul className="footer-list">
            <li>
              <i className="fa fa-address-card footer-intro" />
              Escribime a:
            </li>
            <li className="footer-phone">
              <i className="fa fa-whatsapp fa-fw footer-phone" />
              <a
                href="https://wa.me/5491138522023"
                target="_blank"
                rel="noreferrer"
              >
                +54 9 11 38522023
              </a>
            </li>
            <li className="footer-mail">
              <i className="fa fa-envelope-o fa-fw footer-mail" />
              <a
                href="mailto:infokarinazarfino@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                infokarinazarfino@gmail.com
              </a>
            </li>
            <li>
              <i className="fa fa-map-marker fa-fw footer-ciudad" />
              C.A.B.A
            </li>
            <li>
              <i className="fa fa-map-marker fa-fw footer-provincia" />
              Buenos Aires | Argentina
            </li>
          </ul>
        </div>
        <div className="footer-contact suscribe">
          <span>Ingrese su email para suscribirse a nuestro sitio</span>
          <input
            type="text"
            id="email_suscripcion"
            className="footer-mail-input"
          />

          <button className="footer-mail-input-button">
            <i className="fa fa-envelope fa-fw footer-intro"></i>Subscribirse
          </button>
        </div>
        <div className="footer-contact">
          {/* <FacebookEmbed height="300px" customClassName="footer-facebook" /> */}
        </div>
      </footer>
    );
  }
}

export default Footer;
