import React, { useState } from "react";
import "./WhatsappBtn.scss";
import Modal from "react-modal";
import Input from "../Input/Input";
function WhatsappBtn() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const inputIsCompleted = true; //This controls the green outline when the input has the completed text.

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const _styles = {
    labelStyle: {
      color: "#29a71a",
      textAlign: "left",
    },
    inputStyle: {
      borderColor: `${inputIsCompleted && "#29a71a"}`,

      width: "300px",
      margin: "10px 0",
      height: "40px",
    },
  };

  interface Message {
    email?: string;
    name?: string;
    phone?: string;
    text?: string;
  }

  const [message, setMessage] = useState<Message>({
    text: `Hola, te contacto desde el sitio web:
https://www.karinazarfino.com`,
  });

  const handleSendClick = () => {
    window.open(
      `https://wa.me/5491138522023?text=${message.text}\n\r ${message.name}\n\r ${message.phone}\n\r ${message.email}`,
      "_blank"
    );
  };

  return (
    <>
      <button
        onClick={() => {
          setModalIsOpen(true);
        }}
        className="whatsapp-btn"
        type="button"
      >
        <i className="fa fa-whatsapp"></i>
      </button>

      <Modal
        closeTimeoutMS={300}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        overlayClassName="Overlay"
        className="Modal"
        style={customStyles}
      >
        <div className="whatsappbtn-modal">
          <div className="whatsappbtn-modal-header">
            <i className="fa fa-whatsapp"></i>
            <button onClick={() => setModalIsOpen(false)} type="button">
              <i className="fa fa-times"></i>
            </button>
          </div>
          <form action="">
            <Input
              label="* Nombre"
              name="name"
              labelStyle={_styles.labelStyle}
              inputStyle={_styles.inputStyle}
              onChange={(e) => setMessage({ ...message, name: e })}
            />
            <Input
              label="* Email"
              name="email"
              labelStyle={_styles.labelStyle}
              inputStyle={_styles.inputStyle}
              onChange={(e) => setMessage({ ...message, email: e })}
            />
            <Input
              label="* Teléfono"
              name="phone"
              labelStyle={_styles.labelStyle}
              inputStyle={_styles.inputStyle}
              onChange={(e) => setMessage({ ...message, phone: e })}
            />
            <div className="whatsappbtn-textarea-container">
              <label htmlFor="message" style={{ color: "#29a71a" }}>
                Mensaje
                <textarea
                  name="message"
                  id=""
                  cols={10}
                  className="isCompleted isNotCompleted"
                  onChange={(e) =>
                    setMessage({ ...message, text: e.target.value })
                  }
                >
                  {message.text}
                </textarea>
              </label>
            </div>
          </form>
          <div className="whatsappbtn-btn-container">
            <button type="button" onClick={handleSendClick}>
              Enviar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default WhatsappBtn;
