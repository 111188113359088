import { TextField, makeStyles, createStyles, Theme } from "@material-ui/core";
import { useState } from "react";
import { Country, Price } from "../../../../shared/types";
import SelectorDropdown from "./SelectorDropdown";
import Modal from "./Modal";
import { finalPriceWithDiscount } from "../../helpers/functions";
import { DEFAULT_PRICE } from "../../helpers/defaults";
import { Checkbox } from "./Checkbox";

interface PriceEditProps {
  oldPrice?: Price;
  countries: Country[];
  onCancel: () => void;
  onSave: (p: Price) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    text: {
      position: "relative",
      flexDirection: "column",
      display: "inline-flex",
      padding: "12px",
    },
  })
);

const PriceEdit = ({
  oldPrice,
  countries,
  onCancel,
  onSave,
}: PriceEditProps) => {
  const [price, setPrice] = useState<Price>(oldPrice ?? DEFAULT_PRICE);

  const classes = useStyles();

  return (
    <Modal onSave={() => onSave(price)} onCancel={onCancel}>
      {oldPrice ? (
        <p className={classes.text}>{price.country}</p>
      ) : (
        <SelectorDropdown
          label={"País"}
          onChange={(v) =>
            setPrice({
              ...price,
              country: v,
            })
          }
          values={[
            {
              text: "-",
              value: "-",
            },
            ...countries.map((c) => ({
              text: c.code,
              value: c.code,
            })),
          ]}
        />
      )}
      <TextField
        className={classes.formControl}
        label="Precio"
        type="number"
        value={price.price}
        onChange={(e) =>
          setPrice({ ...price, price: parseInt(e.target.value) })
        }
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        label={
          price.discount > 0 && price.price > 0
            ? `Descuento ( final ${finalPriceWithDiscount(
                price.price,
                price.discount
              )})`
            : "Descuento"
        }
        type="number"
        value={price.discount}
        onChange={(e) =>
          setPrice({ ...price, discount: parseInt(e.target.value) })
        }
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
      />
      <Checkbox
        checked={price.enabled}
        onChange={() => setPrice({ ...price, enabled: !price.enabled })}
        label="Habilitado"
      />
      <Checkbox
        checked={price.hidden}
        onChange={() => setPrice({ ...price, hidden: !price.hidden })}
        label="Ocultar precio"
      />
    </Modal>
  );
};

export default PriceEdit;
