import { useContext } from "react";
import "./Cart.scss";
import { CartDetailsItem } from "./CartDetailsItem";
import { CartContext, InCartItem } from "../providers/CartContextProvider";
import { AppContext } from "../providers/AppContextProvider";
import { useNavigate } from "react-router-dom";
import { TicketActions } from "../actions/ticket";
import { CartItem } from "../../../shared/types";
import { ErrorContext } from "../providers/ErrorContextProvider";

export const Cart = () => {
  const cartContext = useContext(CartContext);
  const appContext = useContext(AppContext);
  const errorContext = useContext(ErrorContext);

  const navigate = useNavigate();

  const handlePayment = async () => {
    if (!appContext.user || !appContext.user.id) return;

    const items = cartContext.getCartItems() as CartItem[];

    if (items.find((x) => (x as any).workshopType === "book")) {
      navigate("/addressselector");
      return;
    }

    const newresponse = await TicketActions.post({
      userId: appContext.user.id,
      cart: items,
      created: Date.now(),
      countryCode: appContext.location.code,
    });

    if (newresponse.failed) {
      errorContext.setMessage({
        message: newresponse.message,
        type: "error",
      });
      return;
    }

    const ticketId = newresponse.id;

    const getresponse = await TicketActions.get({
      id: ticketId,
    });

    if (getresponse.failed) {
      errorContext.setMessage({
        message: getresponse.message,
        type: "error",
      });
      return;
    }

    cartContext.setTicket(getresponse.data);

    navigate("/pay");
  };

  return (
    <>
      <div className="cart-container">
        <div className="form-class">
          <table>
            <thead className="cart-table-head">
              <th>ARTÍCULO</th>
              <th style={{ textAlign: "center" }}></th>
              <th>PRECIO</th>
              <th>SUBTOTAL</th>
            </thead>
            <tbody className="cart-table-body">
              {cartContext.getCartItems().map((item: InCartItem) => {
                return (
                  <CartDetailsItem
                    key={item.id}
                    item={item}
                    onRemove={() => {
                      cartContext.removeItem(item);
                    }}
                  />
                );
              })}
              <tr className="tr-discount">
                <td style={{ textAlign: "right" }}>
                  {/* <span>¿Tenés un cupón de descuento?</span> */}
                </td>

                <tr>
                  <td>
                    {/* <input type="text" className="discount-coupon" /> */}
                  </td>
                  <td>
                    {/* <button className="discount-btn btn">
                      <i className="fa fa-check"></i>Aplicar
                    </button> */}
                  </td>
                </tr>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>
                  <div className="cart-installments">
                    {/* <i className="fa fa-money"></i>
                    <Link
                      to="#"
                      onClick={() => {
                        setModalIsOpen(true);
                      }}
                    >
                      Calcular Cuotas
                    </Link> */}
                  </div>
                </td>
                <td></td>
                <td
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  TOTAL
                </td>
                <td
                  style={{
                    fontSize: "24px",
                  }}
                >
                  {`${
                    appContext.location.currencySign
                  }${cartContext.calculateTotal(cartContext.getCartItems())}`}
                </td>
              </tr>
              <tr className="cart-table-footer">
                <td
                  colSpan={5}
                  style={{
                    textAlign: "right",

                    fontSize: "10px",
                  }}
                >
                  Precios expresados en{" "}
                  {`${appContext.location.currency} (${appContext.location.name})`}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            className="shopping-btn-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px 0px",
            }}
          >
            <button
              className="btn-keep-shopping"
              onClick={() => navigate("/list/workshop")}
            >
              <i className="fa fa-chevron-left"></i>
              Seguir Comprando
            </button>
            {!appContext.user || !appContext.user.id ? (
              <button
                className="btn-continue"
                onClick={() => navigate("/register")}
              >
                Crear cuenta
                <i className="fa fa-chevron-right"></i>
              </button>
            ) : (
              <button className="btn-continue" onClick={handlePayment}>
                {cartContext
                  .getCartItems()
                  .find((x) => (x as any).workshopType === "book")
                  ? "Completar destino"
                  : "Pagar"}
                <i className="fa fa-chevron-right"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
