import { Video } from "../../../shared/types";
import { post, get, GetResponse, GetAllResponse, put, remove } from "./_base";

const path = "videos";

export class VideoActions {
  static post = async (data: Video) => {
    return await post(path, data);
  };
  static get = async (data: Partial<Video>) => {
    return (await get(path, data, {
      single: true,
    })) as GetResponse<Video>;
  };
  static getAll = async (data: Partial<Video>) => {
    return (await get(path, data)) as GetAllResponse<Video>;
  };
  static put = async (data: Video) => {
    return await put(path, data);
  };
  static remove = async (id: string) => {
    return await remove(path, id);
  };
}
