import { useEffect, useState } from "react";
import {
  Button,
  List,
  LinearProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon, Delete } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import ListItem from "../components/ListItem";
import { CardType, CardWithType } from "../../../../shared/types";
import { CardActions } from "../../actions/cards";
import { useNavigate, useParams } from "react-router-dom";
import { getTypeNamePlural } from "../../helpers/functions";

const CardsPage = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState<CardWithType[]>([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const fetchData = async () => {
    const response = await CardActions.getAll({
      type: params.type as CardType,
    });
    setCards(response.data);
    if (!response.failed) setLoading(false);
  };

  const handleClick = (id: string) => {
    navigate(`/backend/card/${cards.find((c) => c.id === id)?.type}/${id}`);
  };

  const handleNewClick = () => {
    navigate(`/backend/card/${params.type}`);
  };

  const handleDelete = async (id: string) => {
    const response = await CardActions.remove(id);

    if (response.failed) return;

    setCards(cards.filter((w) => w.id !== id));
  };

  return (
    <>
      <Typography>{getTypeNamePlural(params.type as CardType)}</Typography>
      <Button variant="contained" color="primary" onClick={handleNewClick}>
        Nuevo
      </Button>
      <IconButton onClick={handleNewClick}>
        <AddIcon />
      </IconButton>
      {loading ? (
        <LinearProgress />
      ) : (
        <List>
          {cards.map((w) => (
            <ListItem
              image={w.image?.path}
              key={w.id}
              title={w.title}
              icons={[
                { action: () => handleClick(w.id ?? ""), icon: <EditIcon /> },
                {
                  action: () => handleDelete(w.id ?? ""),
                  icon: <Delete />,
                },
              ]}
            />
          ))}
        </List>
      )}
    </>
  );
};

export default CardsPage;
