import { TextField, Typography, makeStyles, List } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { News, Workshop } from "../../../../shared/types";
import { WorkshopActions } from "../../actions/workshops";
import EditMode, { PreviewType } from "./EditMode";
import ListItem from "./ListItem";
import Modal from "./Modal";

const useStyles = makeStyles((theme) => ({
  courseread: {
    textAlign: "left",
    width: "100%",
    height: "100%",
  },
  title: {
    textAlign: "left",
    width: "50%",
    paddingBottom: "10px",
  },
  description: {
    textAlign: "left",
    width: "90%",
    paddingBottom: "10px",
  },
  image: {
    paddingBottom: "10px",
  },
  input: {
    display: "none",
  },
}));

interface NewsEditProps {
  news: News;
  onSave: (news: News) => void;
  onCancel: () => void;
  onPreview: (news: News, type: PreviewType) => void;
}

const NewsEdit = (props: NewsEditProps) => {
  const classes = useStyles();

  const [news, setNews] = useState<News>({
    ...props.news,
    workshops: props.news.workshops ?? ["", "", ""],
  });
  const [workshops, setWorkshops] = useState<Workshop[]>([]);
  const [add, setAdd] = useState<number>(-1);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await WorkshopActions.getAllForBack({});

    setWorkshops(
      response.data.filter(
        (w) => !!w.prices.find((p) => p.country === news.country && p.enabled)
      )
    );
  };

  const handleChange = (id: number, wid: string) => {
    const old = news.workshops;
    old[id] = wid;
    setNews({ ...news, workshops: old });
  };

  return (
    <EditMode onSave={() => props.onSave(news)} onCancel={() => props.onCancel}>
      <div className={classes.courseread}>
        <Typography variant={"h2"} style={{ textAlign: "left" }}>
          {`${news.country} - Novedades`}
        </Typography>
        <div>
          <Typography variant={"h6"} style={{ textAlign: "left" }}>
            Promocionados
          </Typography>
          <List>
            {news.workshops.map((wid, idx) => {
              const w = workshops.find((w) => w.id === wid);

              return (
                <ListItem
                  image={w ? w.image?.path : ""}
                  key={w ? `${w.id}${idx}` : idx}
                  title={w ? w.title : "Seleccionar taller/tesa/libro"}
                  description={w ? w.description : ""}
                  icons={[
                    {
                      action: () => setAdd(idx),
                      icon: <Edit />,
                    },
                  ]}
                />
              );
            })}
          </List>
          {add >= 0 && (
            <Modal
              onCancel={() => {
                setAdd(-1);
              }}
            >
              <List>
                {workshops.map((w) => (
                  <ListItem
                    image={w.image?.path}
                    key={w.id}
                    title={w.title}
                    description={w.description}
                    icons={[]}
                    onClick={() => {
                      handleChange(add, w.id ?? "");
                      setAdd(-1);
                    }}
                  />
                ))}
              </List>
            </Modal>
          )}
        </div>
        <br />
        <div>
          <TextField
            className={classes.title}
            required
            label="Link Youtube"
            variant="outlined"
            onChange={(e) => setNews({ ...news, youtubeLink: e.target.value })}
            value={news.youtubeLink}
          />
        </div>
      </div>
    </EditMode>
  );
};

export default NewsEdit;
