import "./PhotoCard.scss";

interface PhotoCardProps {
  openSlide: any;
  src: any;
  title: any;
}

const PhotoCard = (props: PhotoCardProps) => {
  const { src, title, openSlide } = props;
  return (
    <div className="photocard">
      <div className="photocard-image-container">
        <img
          onClick={openSlide}
          className="photocard-image"
          src={src}
          alt="fotos"
        />
      </div>
      {title && <h5 className="photocard-title">{title}</h5>}
    </div>
  );
};

export default PhotoCard;

//Line 22 should have  type === "video" && to avoid using title when not rendering images. Should come from props.
