import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./LoginCard.scss";
import { AuthUserActions } from "../../actions/authUser";
import { AppContext } from "../../providers/AppContextProvider";
import { UserActions } from "../../actions/user";
import { ErrorContext } from "../../providers/ErrorContextProvider";

interface LoginCardState {
  hasAccount: boolean;
  email: string;
  password: string;
}
interface LoginCardProps {
  hasAccount?: boolean;
  recover?: boolean;
}

const LoginCard = ({ hasAccount, recover }: LoginCardProps) => {
  let navigate = useNavigate();
  let appContext = useContext(AppContext);
  let errorContext = useContext(ErrorContext);

  let [state, setState] = useState<LoginCardState>({
    hasAccount: hasAccount ?? true,
    email: "",
    password: "",
  });

  useEffect(() => {
    if (appContext.user) navigate("/myworkshops");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRecover = async () => {
    const response = await AuthUserActions.get({
      email: state.email.trim(),
      recover: true,
    });

    if (response.statusCode === 201 || response.statusCode === 200) {
      errorContext.setMessage({
        message:
          "Contraseña recuperada, revise su mail para obtener la contraseña",
        type: "success",
      });
      navigate("/login");
    }
  };

  const handleLogin = async () => {
    const response = await AuthUserActions.get({
      email: state.email.trim(),
      password: state.password.trim(),
    });

    const user = response.data;

    if (response.statusCode === 201) {
      appContext.setUser(user);
      navigate("/myworkshops");
    }
  };

  const handleRegister = async () => {
    const user = {
      email: state.email.trim(),
    };
    const response = await UserActions.post(user);

    if (response.statusCode === 201) {
      errorContext.setMessage({
        message: "Usuario creado, revise su mail para obtener la contraseña",
        type: "success",
      });
      setState({ ...state, hasAccount: true });
    }
  };

  const hasAccountChanged = () => {
    setState({ ...state, hasAccount: !state.hasAccount });
  };

  return (
    <div style={{ minHeight: "500px" }}>
      <div className="login-container">
        {!recover ? (
          <>
            <h2>Iniciar sesión</h2>
            <div className="input-container">
              <label>
                <input
                  type="radio"
                  checked={!state.hasAccount}
                  onChange={hasAccountChanged}
                />
                No tengo una cuenta
              </label>

              <label>
                <input
                  type="radio"
                  checked={state.hasAccount}
                  onChange={hasAccountChanged}
                />
                Ya tengo una cuenta
              </label>
            </div>
          </>
        ) : (
          <h2>Recuperar contraseña</h2>
        )}
        <div className="email-container">
          <label htmlFor="login_email" className="control-label">
            Email
          </label>
          <input
            id="login_email"
            type="email"
            className="logincard-input-email input-style"
            required={true}
            value={state.email}
            onChange={(e) => setState({ ...state, email: e.target.value })}
          />
        </div>
        {state.hasAccount && (
          <div>
            <div className="password-container">
              <label htmlFor="login_password">Contraseña</label>
              <button className="logincard-btn-password-show">
                <i className="fa fa-eye"></i>
                <span>Mostrar</span>
              </button>
              <input
                id="login_password"
                type="password"
                className="logincard-input-password input-style"
                onChange={(e) =>
                  setState({ ...state, password: e.target.value })
                }
              />
            </div>

            {!recover && (
              <p>
                <Link className="logincard-forgot-password" to="/recover">
                  ¿Olvidó su contraseña?
                </Link>
              </p>
            )}
          </div>
        )}
        <div className="login-button">
          <button
            onClick={
              recover
                ? handleRecover
                : state.hasAccount
                ? handleLogin
                : handleRegister
            }
            className="logincard-btn-enviar"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginCard;
