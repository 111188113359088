import { useContext, useEffect, useState } from "react";
import "./List.css";
import Card from "../components/Card/Card";
import { WorkshopActions } from "../actions/workshops";
import { Workshop } from "../../../shared/types";
import { ErrorContext } from "../providers/ErrorContextProvider";

const MyWorkshops = () => {
  const [workshops, setWorkshops] = useState<Workshop[]>([]);
  const errorContexto = useContext(ErrorContext);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await WorkshopActions.getAllForOwner({});
    const workshops = response.data;
    setWorkshops(workshops);

    if (workshops && workshops.length > 0) return;
    errorContexto.setMessage({
      message: "Una vez adquirido un producto lo podrás ver en esta sección",
      type: "warning",
      permanent: true,
    });
  };

  return (
    <>
      {workshops?.map((w) => (
        <Card
          key={w.id}
          title={w.title}
          description={w.description}
          image={w.image?.path}
          url={`/myworkshop/${w.id}`}
          buttonText={"Ingresar"}
        />
      ))}
    </>
  );
};

export default MyWorkshops;
