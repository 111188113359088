import DrawerItem from "./DrawerItem";
import { getNavigationMenu, NavMenuItem } from "../../navigationMenu";
import { Link } from "react-router-dom";
import "./Drawer.scss";
import { useContext } from "react";
import { AppContext } from "../../providers/AppContextProvider";

interface DrawerProps {
  open?: any;
  handleDrawer?: any;
}

//This has some bugs that needs to be fixed:
//Click not working properly on arrow when redirect is possible
//When you open a subitem and the drawer is scrolled, it doesn' take you to top of the page
//Overlay for submenus needs to be applied

function Drawer(props: DrawerProps) {
  const appContext = useContext(AppContext);

  const isLogged = appContext.user && appContext.user.token;

  const { handleDrawer, open } = props;
  return (
    <nav className={`${!open ? "drawer-off" : "drawer-on"} drawer-container`}>
      <div className="slidemenu-container">
        <span>Cerrar</span>
        <span className="close" onClick={() => handleDrawer(false)}></span>
      </div>
      <div>
        <span className="karina">
          KARINA ZARFINO
          <br />
          Arte, Comunicación y Terapia Psicoenergética
        </span>
      </div>
      {getNavigationMenu(!!appContext.user).map(
        (navigation: NavMenuItem, key: any) => {
          const depthLevel = 0;
          return (
            <DrawerItem
              key={key}
              navigation={navigation}
              depthLevel={depthLevel}
            />
          );
        }
      )}
      <div>
        {isLogged ? (
          <>
            <div>
              <Link to={"/user"} title={"user"}>
                Mi Cuenta
              </Link>
            </div>
            <br />
            <div>
              <Link
                to={"#"}
                title={"logout"}
                onClick={() => appContext.setUser()}
              >
                Salir
              </Link>
            </div>
            {appContext.user?.roles?.some((r) => r === "admin") && (
              <div>
                <Link to={"/backend"} title={"backend"}>
                  Ir al backend
                </Link>
              </div>
            )}
          </>
        ) : (
          <ul className="drawer-icons-container">
            <li>
              <Link to={"/login"} title={"login"}>
                <i className="fa fa-fw fa-sign-in"></i>
                <span>Entrar</span>
              </Link>
            </li>
            <li>
              <Link to={"/register"} title={"register"}>
                <i className="fa fa-fw fa-user-plus"></i>
                <span>Crear cuenta</span>
              </Link>
            </li>
          </ul>
        )}
        <ul className="drawer-icons-container">
          {appContext.socialIcons.map((si) => (
            <li>
              <a href={si.shareURL} target="_blank" rel="noreferrer">
                <i className={`fa-fw ${si.iconClass}`}></i>
                {si.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default Drawer;
