import "./Card.scss";
import RedButton from "../Button/RedButton";
import { Link } from "react-router-dom";

interface CardProps {
  title: string;
  description: string;
  image?: string;
  url: string;
  buttonText?: string;
}

const Card = (props: CardProps) => {
  const { description, title, image, url } = props;

  const icon = <span className="card-btn-icon fa fa-chevron-right"></span>;

  return (
    <div className="card-container">
      {image && (
        <Link to={url}>
          <img className="card-image" src={image} alt="" />
        </Link>
      )}
      <div className="card-text">
        <Link className="link title" to={url}>
          <h4>{title}</h4>
        </Link>
        {description}
        <RedButton
          linkCustomClass="card-btn"
          containerCustomClass="card-btn-container"
          to={url}
          text={props.buttonText ?? "Ver más"}
          icon={icon}
        />
      </div>
    </div>
  );
};

export default Card;
