import {
  Checkbox,
  ListItem,
  FormControl,
  FormControlLabel,
  makeStyles,
  createStyles,
  IconButton,
  Theme,
  ListItemText,
} from "@material-ui/core";
import { Price } from "../../../../shared/types";
import { Edit, Delete } from "@material-ui/icons";
import {
  getCountrySign,
  finalPriceWithDiscount,
} from "../../helpers/functions";
import { useContext } from "react";
import { AppContext } from "../../providers/AppContextProvider";

interface PriceViewProps {
  price: Price;
  onEdit: () => void;
  onRemove: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    striketrough: {
      textDecoration: "line-through",
      width: "20%",
      textAlign: "center",
    },
    country: {
      width: "7%",
      textAlign: "right",
    },
    price: {
      width: "20%",
      textAlign: "center",
    },
    final: {
      width: "20%",
      textAlign: "center",
    },
    discount: {
      width: "20%",
      textAlign: "center",
    },
    icon: {
      width: "45px",
      textAlign: "center",
    },
  })
);

const PriceView = ({ price, onEdit, onRemove }: PriceViewProps) => {
  const classes = useStyles();

  const appContext = useContext(AppContext);

  const countrySign = getCountrySign(price.country, appContext.countries) ?? "";

  return (
    <ListItem>
      <ListItemText className={classes.country} primary={price.country} />
      <ListItemText
        className={price.discount > 0 ? classes.striketrough : classes.price}
        primary={price.discount > 0 ? `${countrySign}${price.price}` : ""}
      />
      <ListItemText
        className={classes.final}
        primary={
          price.discount > 0
            ? `${countrySign}${finalPriceWithDiscount(
                price.price,
                price.discount
              )}`
            : `${countrySign}${price.price}`
        }
      />
      <ListItemText
        className={classes.discount}
        primary={price.discount > 0 ? `${price.discount}%` : "-"}
      />
      <FormControl component="fieldset" className={classes.formControl}>
        <FormControlLabel
          labelPlacement="start"
          control={<Checkbox name="enabled" checked={price.enabled} />}
          label="Habilitado"
        />
      </FormControl>
      <IconButton aria-label="edit" onClick={onEdit} className={classes.icon}>
        <Edit />
      </IconButton>
      <IconButton
        aria-label="delete"
        onClick={onRemove}
        className={classes.icon}
      >
        <Delete />
      </IconButton>
    </ListItem>
  );
};

export default PriceView;
