import Button from "./Button";
import { makeStyles } from "@material-ui/core/styles";
import SplitButton from "./SplitButton";

const useStyles = makeStyles((theme) => ({
  workshopread: {
    width: "100%",
    textAlign: "right",
    height: "100%",
    paddingBottom: "100px",
  },
  buttons: { "& > *": { margin: theme.spacing(1) } },
}));
export interface EditModeProps {
  children?: React.ReactNode;
  onSave?: () => void;
  onCancel?: () => void;
  onPreview?: (type: PreviewType) => void;
  cancelText?: string;
  saveText?: string;
}

export type PreviewType = "FullPage" | "List";

const EditMode = (props: EditModeProps) => {
  const classes = useStyles();

  return (
    <div className={classes.workshopread}>
      {props.children}
      <div className={classes.buttons}>
        {props.onPreview && (
          <SplitButton
            text="Vista Previa"
            buttons={[
              {
                text: "En página",
                action: () => {
                  if (props.onPreview) props.onPreview("FullPage");
                },
              },
              {
                text: "En lista",
                action: () => {
                  if (props.onPreview) props.onPreview("List");
                },
              },
            ]}
          />
        )}
        {props.onSave && (
          <Button variant="contained" color="primary" onClick={props.onSave}>
            {props.saveText ?? "Aceptar"}
          </Button>
        )}
        {props.onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={props.onCancel}
          >
            {props.cancelText ?? "Cancelar"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditMode;
