import { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  List,
  LinearProgress,
  Typography,
  TextField,
} from "@material-ui/core";
import { Add as AddIcon, Delete, Search } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import ListItem from "../components/ListItem";
import { User } from "../../../../shared/types";
import { useNavigate } from "react-router-dom";
import { UserActions } from "../../actions/user";

const ICON_SIZE = "50px";

const UsersPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<string>();
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await UserActions.getAll({});
    setUsers(response.data);
    if (!response.failed) setLoading(false);
  };

  const handleClick = (id: string) => {
    navigate(`/backend/user/${id}`);
  };

  const handleNewClick = () => {
    navigate(`/backend/user/`);
  };

  const handleDelete = async (id: string) => {
    const response = await UserActions.remove(id);

    if (response.failed) return;

    setUsers(users.filter((w) => w.id !== id));
  };

  const filterUsers = (u: User) => {
    if (!filter) return true;

    if (
      u.email.includes(filter) ||
      u.firstname?.includes(filter) ||
      u.lastname?.includes(filter) ||
      u.uid?.includes(filter) ||
      u.phoneNumber?.includes(filter) ||
      u.mobileNumber?.includes(filter)
    )
      return true;

    return false;
  };

  return (
    <>
      <Typography variant="h2">Usuarios</Typography>
      {false && (
        <>
          <Button variant="contained" color="primary" onClick={handleNewClick}>
            Nuevo
          </Button>
          <IconButton onClick={handleNewClick}>
            <AddIcon />
          </IconButton>
        </>
      )}
      <div>
        <TextField
          label="Búsqueda"
          variant="outlined"
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
        />
        <Search style={{ width: ICON_SIZE, height: ICON_SIZE }} />
      </div>
      {loading ? (
        <LinearProgress />
      ) : (
        <List>
          {users
            .filter((u) => filterUsers(u))
            .map((w) => (
              <ListItem
                key={w.id}
                title={w.email}
                description={`${w.firstname ?? ""} ${w.lastname ?? ""}`}
                icons={[
                  {
                    action: () => handleClick(w.id ?? ""),
                    icon: <EditIcon />,
                  },
                  {
                    action: () => handleDelete(w.id ?? ""),
                    icon: <Delete />,
                  },
                ]}
              />
            ))}
        </List>
      )}
    </>
  );
};

export default UsersPage;
