export const PauseIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
  >
    <path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M27,46h-8V14h8V46z M41,46h-8V14h8V46z" />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const PlayIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
  >
    <path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M45.563,30.826l-22,15  C23.394,45.941,23.197,46,23,46c-0.16,0-0.321-0.038-0.467-0.116C22.205,45.711,22,45.371,22,45V15c0-0.371,0.205-0.711,0.533-0.884  c0.328-0.174,0.724-0.15,1.031,0.058l22,15C45.836,29.36,46,29.669,46,30S45.836,30.64,45.563,30.826z" />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const RestartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm4.82-4.924a7 7 0 1 0-1.852 1.266l-.975-1.755A5 5 0 1 1 17 12h-3l2.82 5.076z" />
    </g>
  </svg>
);

export const ForwardIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 330 330"
  >
    <path
      id="XMLID_19_"
      d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M253.105,175.606  l-67.5,67.5c-2.869,2.87-6.706,4.394-10.609,4.394c-1.933,0-3.881-0.373-5.737-1.142c-5.604-2.322-9.26-7.791-9.26-13.858v-31.287  l-41.894,41.893c-2.869,2.87-6.706,4.394-10.609,4.394c-1.933,0-3.881-0.373-5.737-1.142c-5.604-2.322-9.26-7.791-9.26-13.858v-135  c0-6.067,3.655-11.536,9.26-13.858c5.604-2.322,12.058-1.039,16.347,3.252l41.894,41.893V97.5c0-6.067,3.655-11.536,9.26-13.858  c5.605-2.322,12.057-1.039,16.347,3.252l67.5,67.5C258.963,160.251,258.963,169.749,253.105,175.606z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const BackwardIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 330 330"
  >
    <path
      id="XMLID_22_"
      d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M237.5,232.5  c0,6.067-3.654,11.536-9.26,13.858c-1.855,0.769-3.805,1.142-5.736,1.142c-3.904,0-7.74-1.523-10.61-4.394L170,201.213V232.5  c0,6.067-3.654,11.536-9.26,13.858c-1.855,0.769-3.805,1.142-5.736,1.142c-3.904,0-7.74-1.523-10.61-4.394l-67.5-67.5  c-5.858-5.857-5.858-15.355,0-21.213l67.5-67.5c4.288-4.291,10.74-5.574,16.347-3.252c5.605,2.322,9.26,7.791,9.26,13.858v31.287  l41.894-41.893c4.289-4.291,10.741-5.574,16.347-3.252c5.605,2.322,9.26,7.791,9.26,13.858V232.5z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
