import { useState } from "react";
import { Link } from "react-router-dom";
import "./NavItem.scss";
import Dropdown from "../Dropdown/Dropdown";
import { NavMenuItem } from "../../navigationMenu";

interface DropdownProps {
  item: NavMenuItem;
  depthLevel?: any;
  isSubmenu?: any;
}

const NavItem = (props: DropdownProps) => {
  const [dropdown, setDropdown] = useState(false);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const { item, depthLevel } = props;
  return (
    <li
      className={"navbarbottom-li"}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {item.submenu ? (
        <>
          <div className="navbar-div">
            <Link className="navbarbottom-button" to={item.url ?? "#"}>
              {depthLevel > 0 ? (
                <span className="menu-icon-submenu"></span>
              ) : (
                <span className="menu-icon"></span>
              )}
              {item.title}
            </Link>
          </div>
          <Dropdown
            depthLevel={depthLevel}
            items={item.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <Link className="navbarbottom-button" to={item.url ?? "#"}>
          {item.title}
        </Link>
      )}
    </li>
  );
};

export default NavItem;
