import { Editor } from "@tinymce/tinymce-react";
import { Editor as EditorTiny } from "tinymce";
import { useState } from "react";
import { uploadBlobFile } from "../../helpers/functions";

export interface TextEditorProps {
  className?: string;
  initialText?: string;
  onLateUpdate?: (content: string) => void;
  onUploadClick?: () => string | void;
  onTakeTheEditor?: (editor: EditorTiny) => void;
}

const TextEditor = ({
  className,
  initialText,
  onLateUpdate,
  onUploadClick,
  onTakeTheEditor,
}: TextEditorProps) => {
  const [initialValue] = useState(initialText ?? "");

  return (
    <div className={className}>
      <Editor
        apiKey={"fk2zlel5pli99o7jz30atr6ekzsdvrhcwr88valmy3hwjfko"}
        initialValue={initialValue}
        init={{
          setup: (editor: EditorTiny) => {
            if (onTakeTheEditor) {
              onTakeTheEditor(editor);
            }
            if (!onUploadClick) return;

            editor.ui.registry.addButton("customInsertButton", {
              icon: "upload",
              onAction: function (_) {
                if (!onUploadClick) return;
                onUploadClick();
              },
            });
          },
          skin: "snow",
          icons: "thin",
          placeholder: "Texto",
          height: 400,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help textcolor",
          ],
          toolbar:
            " undo redo | media | customInsertButton | styleselect | fontsizeselect | forecolor backcolor | code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ",
          fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
          images_upload_handler: (b, s, f, pro) =>
            uploadBlobFile(b.blob(), b.filename(), s, f),
        }}
        onEditorChange={(content) => {
          if (onLateUpdate) onLateUpdate(content);
        }}
        outputFormat="html"
      />
    </div>
  );
};

export default TextEditor;
