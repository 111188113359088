import { Card, CardWithType, ICardType } from "../../../shared/types";
import { post, get, GetResponse, GetAllResponse, put, remove } from "./_base";

const path = "cards";

export class CardActions {
  static post = async (data: CardWithType) => {
    return await post(path, data);
  };
  static get = async (data: ICardType & Partial<Card>) => {
    return (await get(path, data, {
      single: true,
    })) as GetResponse<CardWithType>;
  };
  static getAll = async (data: ICardType & Partial<Card>) => {
    return (await get(path, data)) as GetAllResponse<CardWithType>;
  };
  static put = async (data: ICardType & Card) => {
    return await put(path, data);
  };
  static remove = async (id: string) => {
    return await remove(path, id);
  };
}
