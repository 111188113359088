import { useContext } from "react";
import { AppContext } from "../../providers/AppContextProvider";
import "./Socials.scss";

type SocialsProps = {
  className: string;
};

const Socials = ({ className }: SocialsProps) => {
  const appContext = useContext(AppContext);

  return (
    <ul className={`${className} socials-ul`}>
      {appContext.socialIcons.map((social, idx) => (
        <li className="socials-li" key={idx}>
          <a href={social.shareURL} title={social.iconClass}>
            <span
              className={`socials-socicon socicon ${social.iconClass} socicon-square`}
            />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Socials;
