import React, { useContext } from "react";
import { CartContext } from "../providers/CartContextProvider";
import "./BuyDetails.scss";
import { BuyDetailsArticle } from "./BuyDetailsArticle";

function BuyDetails() {
  const cartContext = useContext(CartContext);

  const userData = {
    name: "Pedro Kupersmit",
    email: "pkupersmit@gmail.com",
  };

  const adressData = {
    name: "Pedro Kupersmit",
    address: "Av Simpre Viva 759",
    zipCode: "1111",
    localidad: "Springfield",
    province: "Buenos Aires",
    country: "Argentina",
  };
  const history = {
    saleDate: "13/10/2021 11:14:43",
    saleConfirm: "13/10/2021 11:20:43",
    saleCancel: "13/10/2021 13:14:43",
  };

  const clientStuff = {
    observations:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi deserunt non ?",
    clientNotes:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Explicabo maiores esse totam corrupti minus sint facere sapiente atque cum corporis, dolores cumque voluptatum nisi tenetur ratione reiciendis perspiciatis molestias tempore!",
  };

  return (
    <>
      <div className="buydetails-wrapper">
        <div className="buydetails-table-container">
          <table className="buydetails-first-table">
            <tbody>
              <tr>
                <td>
                  <strong>Orden #75</strong>
                </td>
              </tr>
              <tr className="buydetail-state border-bottom">
                <td>
                  <i className="fa fa-calendar"></i>
                  13/10/21 11:08:02
                </td>
                <td>Estado: Cancelado</td>
              </tr>
            </tbody>
          </table>
          <table className="buydetails-second-table">
            <thead>
              <tr>
                <th className="text-start" style={{ width: "10%" }}></th>
                <th className="text-start" style={{ width: "60%" }}>
                  Articulo
                </th>
                <th className="text-right" style={{ width: "10%" }}>
                  Cantidad
                </th>
                <th style={{ width: "20%" }}>Precio</th>
                <th style={{ width: "10%" }}>Importe</th>
              </tr>
            </thead>
            <tbody>
              {cartContext.getCartItems().map((i) => (
                <BuyDetailsArticle article={i} />
              ))}
            </tbody>
            <tfoot>
              <tr className="border-bottom">
                <td style={{ width: "10%" }}></td>
                <th className="text-right bold" colSpan={3}>
                  Subtotal
                </th>
                <td className="text-right bold">2.600,00 </td>
              </tr>
              <tr className="border-bottom">
                <td></td>
                <th className="text-right" colSpan={3}>
                  Gastos de envío (Entrega rápida a domicilio)
                </th>
                <td className="text-right">0,00 </td>
              </tr>
              <tr className="border-bottom">
                <td className="td_foto"></td>
                <th className="text-right" colSpan={3}>
                  Recargo forma de pago (Contra Reembolso)
                </th>

                <td className="text-right">0,00</td>
              </tr>
              <tr>
                <td></td>
                <th className="text-right" colSpan={3}>
                  Total ARS
                </th>
                <td className="text-right">2.600,00</td>
              </tr>
            </tfoot>
          </table>
          <div className="border-bottom purchase-notes">
            <div>
              <p className="bold">Observaciones del cliente</p>
              <div>{clientStuff.observations}</div>
            </div>
            <div>
              <p className="bold">Notas para el cliente</p>
              <div>{clientStuff.clientNotes}</div>
            </div>
          </div>
          <div className="btn-container">
            <button className="btn-shopping">
              <i className="fa fa-chevron-left"></i>
              Compras
            </button>
          </div>
        </div>
        <div className="buydetails-userinformation">
          <div className="userdata-container wrapper">
            <div className="username-div">
              <i className="fa fa-user"></i>
              <span>{userData.name}</span>
            </div>
            <div>
              <span>Email:</span>
              <span> {userData.email}</span>
            </div>
          </div>
          <div className="delivery-address-container wrapper">
            <p>Direccion de entrega</p>
            <div className="delivery-information">
              <div>
                <span className="bold">Nombre: </span>
                <span>{adressData.name}</span>
              </div>
              <div>
                <span className="bold">Dirección: </span>
                <span>{adressData.address}</span>
              </div>
              <div>
                <span className="bold">Código Postal: </span>
                <span>{adressData.zipCode}</span>
              </div>
              <div>
                <span className="bold">Localidad: </span>
                <span>{adressData.localidad}</span>
              </div>
              <div>
                <span className="bold">Provincia: </span>
                <span>{adressData.province}</span>
              </div>
              <div>
                <span className="bold">País: </span>
                <span>{adressData.country}</span>
              </div>
            </div>
          </div>
          <div className="delivery-address-container wrapper">
            <p>Historial</p>
            <div className="delivery-information">
              <div>
                <span className="bold">Fecha de venta: </span>
                <span>{history.saleDate}</span>
              </div>
              <div>
                <span className="bold">Fecha de confirmación: </span>
                <span>{history.saleConfirm}</span>
              </div>
              <div>
                <span className="bold">Fecha de cancelación: </span>
                <span>{history.saleCancel}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuyDetails;
