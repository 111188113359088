import { useState } from "react";
import RedButton from "../Button/RedButton";
import "./ToggableContent.scss";
import { VideoPlayer } from "./VideoPlayer";

interface ToggableContentProps {
  content?: string;
  title: string;
  visible?: boolean;
  file?: string;
}

const startVideoTag = `<video `;
const endVideoTag = "</video>";
const srcStart = `src="`;

const ToggableContent = ({
  content: rawContent,
  title,
  visible,
  file,
}: ToggableContentProps) => {
  const [state, setState] = useState<boolean>(visible ?? false);

  const processContent = (rawContent: string) => {
    let elements = [];
    let findingVideos = true;
    let contentLeftToAnalyze = rawContent;
    while (findingVideos) {
      const videoFindings = findVideo(contentLeftToAnalyze);

      if (videoFindings.video) {
        elements.push(
          <div
            className="carddetails-content"
            dangerouslySetInnerHTML={{
              __html: videoFindings.beforeVideo ?? "",
            }}
          />
        );
        elements.push(videoFindings.video);
        contentLeftToAnalyze = videoFindings.afterVideo;
      } else {
        elements.push(
          <div
            className="carddetails-content"
            dangerouslySetInnerHTML={{
              __html: videoFindings.leftContent ?? "",
            }}
          />
        );
        findingVideos = false;
      }
    }
    return elements;
  };

  const parseVideoSource = (where: string) => {
    const from = where.indexOf(srcStart) + srcStart.length;

    const to = where.slice(from).indexOf(`"`);

    return <VideoPlayer src={where.slice(from, from + to)}></VideoPlayer>;
  };

  const findVideo = (rawContent: string) => {
    const indexOfVideo = rawContent.indexOf(startVideoTag);

    if (indexOfVideo >= 0) {
      const indexOfEndVideo =
        rawContent.indexOf(endVideoTag) + endVideoTag.length;

      return {
        beforeVideo: rawContent.slice(0, indexOfVideo),
        video: parseVideoSource(
          rawContent.slice(indexOfVideo, indexOfEndVideo)
        ),
        afterVideo: rawContent.slice(indexOfEndVideo),
      };
    }

    return { leftContent: rawContent };
  };

  const content = rawContent ? processContent(rawContent) : [];
  return (
    <>
      <RedButton text={title} onClick={() => setState(!state)} />
      {state && (
        <>
          {content}
          {file && (
            <a href={file} download>
              Descargar archivo
            </a>
          )}
        </>
      )}
    </>
  );
};

export default ToggableContent;
