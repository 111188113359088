import ReactPixel from "react-facebook-pixel";
import { Navigate, useLocation } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/NavBar/NavBar";
import "../App.css";
import { useContext, useEffect } from "react";
import { AppContext } from "../providers/AppContextProvider";
import Message from "../components/Message/Message";
import { ErrorContext } from "../providers/ErrorContextProvider";
import { CartContextProvider } from "../providers/CartContextProvider";
import { ContentContextProvider } from "../providers/ContentContextProvider";

interface Props {
  component: React.ComponentType;
  loginRequired?: boolean;
}

const BasePage: React.FC<Props> = ({
  component: RouteComponent,
  loginRequired,
}) => {
  const appContext = useContext(AppContext);
  const errorContext = useContext(ErrorContext);

  const isAuthenticated = !!appContext.user;

  const { pathname } = useLocation();

  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view
  }, [pathname]);

  useEffect(() => {
    errorContext.setMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if ((loginRequired && isAuthenticated) || !loginRequired) {
    return (
      <div className="app">
        <ContentContextProvider>
          <CartContextProvider>
            <NavBar />
            {errorContext.message && (
              <div style={{ marginTop: "30px" }}>
                <Message message={errorContext.message} />
              </div>
            )}

            <RouteComponent />
          </CartContextProvider>
        </ContentContextProvider>
        <Footer />
      </div>
    );
  }

  return <Navigate to="/login" />;
};

export default BasePage;
