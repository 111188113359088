import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";
import { AuthUser, Country, SocialIcon } from "../../../shared/types";
import { CountryActions } from "../actions/country";
import { setLogoutCallback, setToken } from "../actions/_base";
import useGeoLocation from "react-ipgeolocation";
import { setCountry } from "./../actions/_base";
import { isDevEnv } from "./../helpers/functions";
import { DEFAULT_COUNTRY } from "../helpers/defaults";

interface AppContent {
  user?: AuthUser;
  countries: Country[];
  location: Country;
  socialIcons: SocialIcon[];
}

interface IAppContext extends AppContent {
  setUser: (user?: AuthUser) => void;
  loading: boolean;
}

const DEFAULT: IAppContext = {
  location: DEFAULT_COUNTRY,
  countries: [],
  setUser: () => undefined,
  loading: true,
  socialIcons: [
    {
      iconClass: "fa fa-facebook",
      shareURL: "https://www.facebook.com/karinazarfino447/",
      name: "Facebook",
    },
    {
      iconClass: "fa fa-instagram",
      shareURL: "https://www.instagram.com/karinazarfino/",
      name: "Instagram",
    },
    {
      iconClass: "fa fa-youtube",
      shareURL: "https://www.youtube.com/channel/UCuQ4osqxqprdYHO-BZr10LA",
      name: "YouTube",
    },
    {
      iconClass: "fa fa-vimeo",
      shareURL: "https://vimeo.com/user884753",
      name: "Vimeo",
    },
    {
      iconClass: " fa fa-linkedin",
      shareURL: "https://ar.linkedin.com/in/karinazarfino",
      name: "LinkedIn",
    },
    {
      iconClass: "fa fa-envelope",
      shareURL: "mailto:infokarinazarfino@gmail.com",
      name: "Mail",
    },
  ],
};

export const AppContext = createContext<IAppContext>(DEFAULT);

interface AppContextProviderProps {
  children?: React.ReactNode;
}

export const AppContextProvider = (props: AppContextProviderProps) => {
  const setUser = (user?: AuthUser) => {
    if (!user && !Cookies.get("user")) return;

    if (!user) Cookies.remove("user");
    else Cookies.set("user", JSON.stringify(user));
    setAppState({ ...app, user });
  };
  const [app, setAppState] = useState<IAppContext>({
    ...DEFAULT,
    setUser,
  });

  const { children } = props;

  const geoLocation = useGeoLocation();
  const search = window.location.search;
  const from = new URLSearchParams(search).get("from");

  useEffect(() => {
    if ((!geoLocation.country && !from) || !app.loading) return;
    initContext(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoLocation]);

  const getCountry = (from: string, countries: Country[]): Country => {
    const currentCountry = countries?.find((c) => c.code === from && c.enabled);

    //If no country, we return the default
    if (!currentCountry)
      return countries?.find((c) => c.isDefault) || countries[0];

    if (!currentCountry.enabled) {
      // If country is not enabled, we try to reroute
      if (currentCountry.reroute)
        return getCountry(currentCountry.reroute, countries);
      // If country is not enabled and can't reroute we return the default
      else return countries.find((c) => c.isDefault) || countries[0];
    }

    return currentCountry;
  };

  const initContext = async () => {
    if (
      window.location.protocol === "http:" &&
      !window.location.href.includes("localhost")
    )
      window.location.href = "https://karinazarfino.com";

    const response = await CountryActions.getAll({});
    const countries = response.data;

    const userCookie = Cookies.get("user");
    let user: AuthUser | undefined = undefined;

    if (userCookie) user = JSON.parse(userCookie);

    const country =
      isDevEnv() && from
        ? getCountry(from, countries)
        : getCountry(geoLocation.country, countries);

    setLogoutCallback(() => {
      setUser();
    });

    setCountry(country);
    setToken(user?.token);
    setAppState({
      ...app,
      user: user,
      countries,
      loading: false,
      location: country,
    });
  };

  return (
    <AppContext.Provider value={app}>
      {!app.loading && children}
    </AppContext.Provider>
  );
};
