import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Price, WorkshopType } from "../../../../shared/types";
import { finalPriceWithDiscount } from "../../helpers/functions";
import { AppContext } from "../../providers/AppContextProvider";
import { CartContext } from "../../providers/CartContextProvider";
import "./ItemLong.scss";

interface ItemLongProps {
  id: string;
  type: WorkshopType;
  to: string;
  discount?: number;
  price?: Price;
  description?: string;
  thumb?: string;
  title: string;
  isBuying?: boolean;
}

const ItemLong = ({
  id,
  type,
  discount,
  description,
  price,
  thumb,
  title,
  to,
  isBuying,
}: ItemLongProps) => {
  const appContext = useContext(AppContext);
  const [fav, setFav] = useState(false);
  const [removeFav, setRemoveFav] = useState(false);
  const [ammount, setAmmount] = useState(0);

  const cartContext = useContext(CartContext);

  const handleQuantityChange = (q: number) => {
    cartContext.addItem(id, type, q);
  };

  useEffect(() => {
    if (ammount === 0) return;
    handleQuantityChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ammount]);

  return (
    <li className="itemlong">
      <div className="itemlong-card">
        {discount && (
          <span className="itemlong-card-discount">{discount}% OFF</span>
        )}
        <Link to={to} className="itemlong-title-link">
          <img alt={title} src={thumb} className="itemlong-img" />
          <h4 className="itemlong-title">{title}</h4>
        </Link>
        <p className="itemlong-text">{description}</p>
        {price && price.discount > 0 && (
          <p className="itemlong-price">
            <span className="itemlong-oldprice">
              {`${appContext?.location?.currencySign}${price.price}`}
              <sup>00</sup>
            </span>
            <br />
            {`${appContext?.location?.currencySign}${finalPriceWithDiscount(
              price.price,
              price.discount
            )}`}
            <sup>00</sup>
            <span className="itemlong-currencycode">
              {appContext?.location?.currency}
            </span>
          </p>
        )}
        {price && !price.hidden && (!price?.discount || price.discount <= 0) && (
          <p className="itemlong-price">
            <br />
            {`${appContext?.location?.currencySign}${price.price}`}
            <sup>00</sup>
            <span className="itemlong-currencycode">
              {appContext?.location?.currency}
            </span>
          </p>
        )}
        {isBuying && price && !price.hidden && (
          <div className="itemlong-buy-container">
            {/* <div className="itemlong-btn-container">
              <button
                className="plus input-btn"
                onClick={() => {
                  setAmmount(ammount + 1);
                }}
              >
                <i className="fa fa-plus"></i>
              </button>
              <input
                type="number"
                value={ammount}
                onChange={(e) => setAmmount(parseInt(e.target.value))}
              />
              <button
                className="minus input-btn"
                onClick={() => {
                  let newAmmount = ammount - 1;

                  if (newAmmount < 0) newAmmount = 0;
                  setAmmount(newAmmount);
                }}
              >
                <i className="fa fa-minus"></i>
              </button>
            </div> */}
            <button
              className="itemlong-buy-btn"
              onClick={() => {
                setAmmount(1);
              }}
            >
              <i className="fa fa-shopping-cart"></i>
              <span>Comprar Ahora</span>
            </button>
          </div>
        )}
        {false && appContext.user && (
          <button
            className="itemlong-fav-container"
            onClick={() => setFav(!fav)}
            onMouseEnter={() => {
              fav && setRemoveFav(true);
            }}
            onMouseLeave={() => {
              fav && setRemoveFav(false);
            }}
          >
            {!fav ? (
              <>
                <i className="fa fa-heart"></i>
                <span>Agregar a favoritos</span>
              </>
            ) : (
              <>
                <i className="fa fa-heart"></i>
                {fav && !removeFav && <span>Favorito</span>}
                {removeFav && (
                  <span className="remove" onClick={() => setFav(!fav)}>
                    Quitar de favoritos
                  </span>
                )}
              </>
            )}
          </button>
        )}
      </div>
    </li>
  );
};

export default ItemLong;

//{/* <i className="fa fa-spinner"></i> */}
