import { useState } from "react";
import { useContext } from "react";
import { Price } from "../../../../shared/types";
import { priceFinal } from "../../helpers/functions";
import { AppContext } from "../../providers/AppContextProvider";
import "./CardDetails.scss";

interface CardDetailsProps {
  title?: string;
  image?: string;
  description?: string;
  content?: string;
  children?: React.ReactNode;
  price?: Price;
  removeSocials?: boolean;
  onClick?: () => void;
}

const CardDetails = ({
  image,
  title,
  description,
  content,
  children,
  price,
  removeSocials,
  onClick,
}: CardDetailsProps) => {
  const appContext = useContext(AppContext);
  const [fav, setFav] = useState(false);
  const [removeFav, setRemoveFav] = useState(false);

  return (
    <>
      <div className="carddetails-container">
        <div className="innercard-container">
          <div>
            {price && !price.hidden && price.discount > 0 && (
              <span className="carddetails-discount">
                {price.discount}% OFF
              </span>
            )}

            {image && <img className="carddetails-image" src={image} alt="" />}
            {false && !removeSocials && (
              <ul className="social-icons">
                {appContext.socialIcons.map((icon, idx) => {
                  return (
                    <li
                      key={idx}
                      className={icon.iconClass}
                      onClick={() => window.open(icon.shareURL)}
                    />
                  );
                })}
              </ul>
            )}
            {false && price && (
              <button
                className="itemlong-fav-container"
                onClick={() => setFav(!fav)}
                onMouseEnter={() => {
                  fav && setRemoveFav(true);
                }}
                onMouseLeave={() => {
                  fav && setRemoveFav(false);
                }}
              >
                {!fav ? (
                  <>
                    <i className="fa fa-heart"></i>
                    <span>Agregar a favoritos</span>
                  </>
                ) : (
                  <>
                    <i className="fa fa-heart"></i>
                    {fav && !removeFav && <span>Favorito</span>}
                    {removeFav && (
                      <span className="remove" onClick={() => setFav(!fav)}>
                        Quitar de favoritos
                      </span>
                    )}
                  </>
                )}
              </button>
            )}
          </div>
          <div className="carddetails-text buying">
            <h4>{title}</h4>
            <span>{description}</span>
            {price && !price.hidden && (
              <>
                <div className="carddetails-price-container"></div>
                <div className="carddetails-price-container">
                  {!!price.discount && price.discount > 0 && (
                    <div className="carddetails-price">
                      <span>
                        {price.price}
                        <sup>00</sup>
                        <span>{appContext.location.currency}</span>
                      </span>
                    </div>
                  )}
                  <div className="carddetails-price-discount">
                    <span>
                      {priceFinal(price)}
                      <sup>00</sup>
                      <span>{appContext.location.currency}</span>
                    </span>
                  </div>
                </div>
                <button className="carddetails-buy-btn" onClick={onClick}>
                  <i className="fa fa-shopping-cart"></i>
                  <span>Comprar Ahora</span>
                </button>
              </>
            )}
          </div>
        </div>
        <div
          className="carddetails-content"
          dangerouslySetInnerHTML={{
            __html: content ?? "",
          }}
        />
        {children}
      </div>
    </>
  );
};

export default CardDetails;
