import { useContext, useState } from "react";
import "./Cart.scss";
import { CartContext } from "../providers/CartContextProvider";
import { AppContext } from "../providers/AppContextProvider";
import { useNavigate } from "react-router-dom";
import { TicketActions } from "../actions/ticket";
import { CartItem, Address } from "../../../shared/types";
import { ErrorContext } from "../providers/ErrorContextProvider";
import Input from "../components/Input/Input";
import { DEFAULT_ADDRESS } from "../helpers/defaults";

export const AddressSelector = () => {
  const cartContext = useContext(CartContext);
  const appContext = useContext(AppContext);
  const errorContext = useContext(ErrorContext);

  const [address, setAddress] = useState<Address>({
    ...DEFAULT_ADDRESS,
    fullName: `${appContext.user?.firstname ?? ""} ${
      appContext.user?.lastname ?? ""
    },`,
    phone: appContext.user?.phoneNumber,
  });

  const navigate = useNavigate();

  const _styles = {
    inputStyle: {
      width: "300px",
      margin: "10px 0px",
      padding: "0px 5px",
    },
    labelStyle: {
      display: "flex",
      flexDirection: "column",
    },
    areaCode: {
      width: "50px",
      margin: "10px 5px",
      padding: "0px 5px",
    },
    number: {
      width: "220px",
    },
  };

  const handlePayment = async () => {
    if (!appContext.user || !appContext.user.id) return;

    const items = cartContext.getCartItems() as CartItem[];

    const newresponse = await TicketActions.post({
      userId: appContext.user.id,
      cart: items,
      created: Date.now(),
      countryCode: appContext.location.code,
      address: { ...address, country: appContext.location.code },
    });

    if (newresponse.failed) {
      errorContext.setMessage({
        message: newresponse.message,
        type: "error",
      });
      return;
    }

    const ticketId = newresponse.id;

    const getresponse = await TicketActions.get({
      id: ticketId,
    });

    if (getresponse.failed) {
      errorContext.setMessage({
        message: getresponse.message,
        type: "error",
      });
      return;
    }

    if (getresponse.data.address?.street !== address.street) {
      errorContext.setMessage({
        message: "Error creando ticket ( UNK_ADDR )",
        type: "error",
      });
      return;
    }

    cartContext.setTicket(getresponse.data);

    navigate("/pay");
  };

  return (
    <>
      <div className="cart-container">
        <div className="form-class">
          <div style={{ paddingLeft: "3%" }}>
            <p>Direccion de entrega</p>
            <div>
              <div>
                <Input
                  labelStyle={_styles.labelStyle}
                  type="text"
                  inputStyle={_styles.inputStyle}
                  label="Nombre Completo"
                  value={address?.fullName}
                  onChange={(e) => setAddress({ ...address, fullName: e })}
                />
              </div>
              <div>
                <Input
                  labelStyle={_styles.labelStyle}
                  type="text"
                  inputStyle={_styles.inputStyle}
                  label="Teléfono"
                  value={address?.phone}
                  onChange={(e) => setAddress({ ...address, phone: e })}
                />
              </div>
              <div>
                <Input
                  labelStyle={_styles.labelStyle}
                  type="text"
                  inputStyle={_styles.inputStyle}
                  label="Calle"
                  value={address?.street}
                  onChange={(e) => setAddress({ ...address, street: e })}
                />
              </div>
              <div>
                <Input
                  labelStyle={_styles.labelStyle}
                  type="text"
                  inputStyle={_styles.inputStyle}
                  label="Numero"
                  value={address?.number}
                  onChange={(e) => setAddress({ ...address, number: e })}
                />
              </div>
              <div>
                <Input
                  labelStyle={_styles.labelStyle}
                  type="text"
                  inputStyle={_styles.inputStyle}
                  label="Piso"
                  value={address?.floor}
                  onChange={(e) => setAddress({ ...address, floor: e })}
                />
              </div>
              <div>
                <Input
                  labelStyle={_styles.labelStyle}
                  type="text"
                  inputStyle={_styles.inputStyle}
                  label="Departamento"
                  value={address?.appartment}
                  onChange={(e) => setAddress({ ...address, appartment: e })}
                />
              </div>
              <div>
                <Input
                  labelStyle={_styles.labelStyle}
                  type="text"
                  inputStyle={_styles.inputStyle}
                  label="Codigo Postal"
                  value={address?.code}
                  onChange={(e) => setAddress({ ...address, code: e })}
                />
              </div>
              <div>
                <Input
                  labelStyle={_styles.labelStyle}
                  type="text"
                  inputStyle={_styles.inputStyle}
                  label="Provincia"
                  value={address?.subCountry}
                  onChange={(e) => setAddress({ ...address, subCountry: e })}
                />
              </div>
              <div>
                <span className="bold">País: </span>
                {appContext.location.name}
              </div>
            </div>
          </div>
          <div
            className="shopping-btn-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px 0px",
            }}
          >
            <button
              className="btn-keep-shopping"
              onClick={() => navigate("/cart")}
            >
              <i className="fa fa-chevron-left"></i>
              Volver
            </button>
            {!appContext.user || !appContext.user.id ? (
              <button
                className="btn-continue"
                onClick={() => navigate("/register")}
              >
                Crear cuenta
                <i className="fa fa-chevron-right"></i>
              </button>
            ) : (
              <button className="btn-continue" onClick={handlePayment}>
                Pagar
                <i className="fa fa-chevron-right"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
