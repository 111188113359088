import {
  Modal as ModalMUI,
  Paper,
  Theme,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import EditMode from "./EditMode";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "70%",
      height: "80%",
      maxHeight: "80%",
      transform: "translate(-50%, -50%)",
      padding: theme.spacing(2, 4, 3),
    },
    modal: {
      width: "100%",
      height: "100%",
    },
    scroll: {
      height: "90%",
      overflowX: "hidden",
      overflowY: "auto",
    },
  })
);
export interface ModalProps {
  children?: React.ReactNode;
  onSave?: () => void;
  onCancel?: () => void;
  cancelText?: string;
  saveText?: string;
}

export type PreviewType = "FullPage" | "List";

const Modal = ({
  children,
  onSave,
  onCancel,
  cancelText,
  saveText,
}: ModalProps) => {
  const classes = useStyles();

  return (
    <ModalMUI
      disableEnforceFocus
      open={true}
      onClose={onCancel}
      className={classes.modal}
    >
      <Paper className={classes.paper}>
        <EditMode
          cancelText={cancelText}
          saveText={saveText}
          onSave={onSave}
          onCancel={onCancel}
        >
          <div className={classes.scroll}>{children}</div>
        </EditMode>
      </Paper>
    </ModalMUI>
  );
};

export default Modal;
