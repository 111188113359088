import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import Button from "./Button";
import { uploadFile } from "../../helpers/functions";
import "./FileUploader.scss";
import { Message } from "../../providers/ErrorContextProvider";

interface State {
  dragging: boolean;
  file?: File;
  uploading: boolean;
}

interface FileUploaderProps {
  onUpload: (image: string) => void;
  onError: (m: Message) => void;
  selectFileText: string;
  title?: string;
  uploadText: string;
  path?: string;
}

export const FileUploader = ({
  selectFileText,
  uploadText,
  onUpload,
  onError,
  path,
  title,
}: FileUploaderProps) => {
  let fileUploaderInput: HTMLElement | null = null;

  const DEFAULT_STATE = {
    file: undefined,
    uploading: false,
    dragging: false,
  };

  const [state, setState] = useState<State>(DEFAULT_STATE);

  let dragEventCounter = 0;
  const dragenterListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    dragEventCounter++;
    if (event.dataTransfer.items && event.dataTransfer.items[0]) {
      setState({ ...state, dragging: true });
    } else if (
      event.dataTransfer.types &&
      event.dataTransfer.types[0] === "Files"
    ) {
      // This block handles support for IE - if you're not worried about
      // that, you can omit this
      setState({ ...state, dragging: true });
    }
  };

  const dragleaveListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    dragEventCounter--;

    if (dragEventCounter === 0) {
      setState({ ...state, dragging: false });
    }
  };

  const dropListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    dragEventCounter = 0;
    setState({ ...state, dragging: false });

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setState({ ...state, file: event.dataTransfer.files[0] });
    }
  };

  const overrideEventDefaults = (
    event: Event | React.DragEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onSelectFileClick = () => {
    fileUploaderInput && fileUploaderInput.click();
  };

  const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setState({ ...state, file: event.target.files[0] });
    }
  };

  const resetState = () => {
    setState(DEFAULT_STATE);
  };

  const onUploadFileClick = () => {
    if (!state.file) return;
    setState({ ...state, uploading: true });
    uploadFile(
      path ?? "/fileUploadScript.php",
      state.file,
      (f) => {
        resetState();
        onUpload(f);
      },
      (e) => {
        resetState();
        onError({ message: e, type: "error" });
      }
    );
  };

  useEffect(() => {
    if (!state.file) return;
    onUploadFileClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.file]);

  useEffect(() => {
    window.addEventListener("dragover", (event: Event) => {
      overrideEventDefaults(event);
    });
    window.addEventListener("drop", (event: Event) => {
      overrideEventDefaults(event);
    });
    return () => {
      window.removeEventListener("dragover", overrideEventDefaults);
      window.removeEventListener("drop", overrideEventDefaults);
    };
  }, []);

  return (
    <FileUploaderPresentationalComponent
      dragging={state.dragging}
      file={state.file}
      uploading={state.uploading}
      onSelectFileClick={onSelectFileClick}
      onUploadFileClick={onUploadFileClick}
      onDrag={overrideEventDefaults}
      onDragStart={overrideEventDefaults}
      onDragEnd={overrideEventDefaults}
      onDragOver={overrideEventDefaults}
      onDragEnter={dragenterListener}
      onDragLeave={dragleaveListener}
      onDrop={dropListener}
      uploadText={uploadText}
      title={title}
      selectFileText={selectFileText}
    >
      <input
        ref={(el) => (fileUploaderInput = el)}
        type="file"
        className="file-uploader__input"
        onChange={onFileChanged}
      />
    </FileUploaderPresentationalComponent>
  );
};

type PresentationalProps = {
  children: React.ReactNode;
  dragging: boolean;
  file?: File;
  uploading: boolean;
  onSelectFileClick: () => void;
  onUploadFileClick: () => void;
  onDrag: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnter: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  selectFileText: string;
  uploadText: string;
  title?: string;
};

const FileUploaderPresentationalComponent = (props: PresentationalProps) => {
  const {
    dragging,
    file,
    uploading,
    onSelectFileClick,
    onUploadFileClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
    title,
  } = props;

  let uploaderClasses = "file-uploader";
  if (dragging) {
    uploaderClasses += " file-uploader--dragging";
  }

  const fileName = file ? file.name : title ?? "Imágen";

  return (
    <div
      className={uploaderClasses}
      onDrag={onDrag}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className="file-uploader__contents">
        {uploading ? (
          <CircularProgress />
        ) : (
          <>
            <span className="file-uploader__file-name">{fileName}</span>
            {file ? (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={onUploadFileClick}
              >
                {props.uploadText}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={onSelectFileClick}
              >
                {props.selectFileText}
              </Button>
            )}
          </>
        )}
      </div>
      {props.children}
    </div>
  );
};
