import { Country } from "../../../shared/types";
import { post, get, GetResponse, GetAllResponse, remove, put } from "./_base";

export class CountryActions {
  static post = async (data: Country) => {
    return await post("countries", data);
  };
  static get = async (data: Partial<Country>) => {
    return (await get(`countries`, data, {
      single: true,
    })) as GetResponse<Country>;
  };
  static getAll = async (data: Partial<Country>) => {
    return (await get(`countries`, data)) as GetAllResponse<Country>;
  };
  static remove = async (id: string) => {
    return await remove(`countries`, id);
  };
  static put = async (data: Country) => {
    return await put("countries", data);
  };
}
