import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Workshop as WorkshopType } from "../../../shared/types";
import { WorkshopActions } from "../actions/workshops";
import CardDetails from "../components/CardDetails/CardDetails";
import { AppContext } from "../providers/AppContextProvider";
import { CartContext } from "../providers/CartContextProvider";

const Workshop = () => {
  const appContext = useContext(AppContext);
  const cartContext = useContext(CartContext);
  const [workshop, setWorkshop] = useState<WorkshopType>();
  const { id } = useParams();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await WorkshopActions.get({
      id: id,
      code: appContext.location.code,
    });

    setWorkshop(response.data);
  };

  const handleBuy = () => {
    if (!workshop || !workshop.id) return;
    cartContext.addItem(workshop.id, workshop.type, 1);
  };

  return (
    <div>
      <CardDetails
        image={workshop?.image?.path}
        title={workshop?.title}
        description={workshop?.description}
        content={workshop?.text}
        price={workshop?.price}
        onClick={handleBuy}
      />
    </div>
  );
};

export default Workshop;
