import { useEffect, useState } from "react";
import AboutMeEdit from "../components/AboutMeEdit";
import { AboutMe } from "../../../../shared/types";
import { SectionActions } from "../../actions/sections";
import { PreviewType } from "../components/EditMode";
import { LinearProgress, Box } from "@material-ui/core";

const AboutMePage = () => {
  const [aboutMe, setAboutMe] = useState<AboutMe>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await SectionActions.get<AboutMe>({
      sectionName: "AboutMe",
    });
    setAboutMe(response.data);
    if (!response.failed) setLoading(false);
  };
  const onSave = async (aboutMe: AboutMe) => {
    //TODO: Make loading overlay and use that one for when page is loaded.
    //TODO: Snackbar for confirmation ( modal? )
    setLoading(true);
    await SectionActions.put(aboutMe);

    setAboutMe(aboutMe);
    setLoading(false);
  };
  const onCancel = () => {};
  const onPreview = (aboutMe: AboutMe, type: PreviewType) => {};

  return (
    <>
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        aboutMe && (
          <AboutMeEdit
            aboutMe={aboutMe}
            onSave={onSave}
            onCancel={onCancel}
            onPreview={onPreview}
          />
        )
      )}
    </>
  );
};

export default AboutMePage;
