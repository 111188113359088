import { Component } from "react";
import "./YoutubeEmbed.scss";
interface YoutubeEmbedProps {
  link: string;
  title: string;
}

class YoutubeEmbed extends Component<YoutubeEmbedProps> {
  render() {
    const { link, title } = this.props;
    return (
      <iframe
        className="youtube-iframe"
        title={title}
        width="560"
        height="314"
        src={link}
        allowFullScreen
      ></iframe>
    );
  }
}

export default YoutubeEmbed;
