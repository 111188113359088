import axios, { AxiosRequestConfig } from "axios";
import { Country } from "../../../shared/types";
import { isObject } from "../helpers/functions";
import { Message } from "../providers/ErrorContextProvider";

let absolute: string;

export const getApiUrl = async () => {
  if (absolute) return absolute;
  const r = await fetch("/config.txt");

  const text = (await r.text()).toString();
  absolute = `${window.location.protocol}//${text}`;
  return absolute;
};

let country: Country;
let token: string | undefined = undefined;
let errorCallback: (message?: Message) => void;
let logout: () => void;

const getBaseConfig = (headers?: any): AxiosRequestConfig => ({
  headers: token
    ? {
        Authorization: `Bearer ${token}` ?? "",
      }
    : headers,
});

const handleError = (e: any) => {
  errorCallback({
    message: `Error ${e.response.status}: ${e.response.data.message}`,
    type: "error",
  });

  if (e.response.status === 401 || e.response.status === 403) logout();
};

export const setToken = (t?: string) => (token = t);

export const setCountry = (c: Country) => {
  country = c;
};

export const setErrorCallback = (callback: (message?: Message) => void) => {
  errorCallback = callback;
};

export const setLogoutCallback = (callback: () => void) => {
  logout = callback;
};

export const post = async (path: string, data: any): Promise<PostResponse> => {
  let config: AxiosRequestConfig = {
    ...getBaseConfig({
      "Content-Type": "application/json",
    }),
  };

  try {
    const response = await axios.post(
      country
        ? `${await getApiUrl()}/api/${path}?from=${country.code}`
        : `${await getApiUrl()}/api/${path}`,
      data,
      config
    );

    return { ...response.data, statusCode: response.status };
  } catch (e: any) {
    handleError(e);
    return { ...e.response.data, statusCode: e.response, failed: true };
  }
};

export const put = async (path: string, data: any): Promise<PutResponse> => {
  let config: AxiosRequestConfig = {
    ...getBaseConfig({
      "Content-Type": "application/json",
    }),
  };
  try {
    const response = await axios.put(
      country
        ? `${await getApiUrl()}/api/${path}?from=${country.code}`
        : `${await getApiUrl()}/api/${path}`,
      data,
      config
    );

    return { ...response.data, id: data.id, statusCode: response.status };
  } catch (e: any) {
    handleError(e);
    return { ...e.response.data, statusCode: e.response, failed: true };
  }
};

export const remove = async (
  path: string,
  id: string
): Promise<BaseResponse> => {
  let config: AxiosRequestConfig = {
    ...getBaseConfig(),
    params: { id },
  };

  try {
    const response = await axios.delete(
      country
        ? `${await getApiUrl()}/api/${path}/${id}?from=${country.code}`
        : `${await getApiUrl()}/api/${path}/${id}`,
      config
    );

    return { ...response.data, statusCode: response.status };
  } catch (e: any) {
    handleError(e);
    return { ...e.response.data, statusCode: e.response, failed: true };
  }
};

export interface GetOptions {
  single?: boolean;
  noerror?: boolean;
}

export const get = async (
  path: string,
  data?: any,
  options?: GetOptions
): Promise<BaseResponse> => {
  const all = {
    ...data,
    ...options,
  };

  let params: Record<string, string> = {};

  Object.keys(all).forEach(
    (x) =>
      (params = {
        ...params,
        [x]: isObject(all[x]) ? JSON.stringify(all[x]) : all[x],
      })
  );

  let config: AxiosRequestConfig = {
    ...getBaseConfig(),
    params,
  };

  try {
    const response = await axios.get(
      country
        ? `${await getApiUrl()}/api/${path}?from=${country.code}`
        : `${await getApiUrl()}/api/${path}`,
      config
    );

    return { ...response.data, statusCode: response.status };
  } catch (e: any) {
    if (!options?.noerror) handleError(e);
    return { ...e.response.data, statusCode: e.response, failed: true };
  }
};

export interface BaseResponse {
  message: string;
  statusCode: number;
  failed?: boolean;
}

export interface PostResponse extends BaseResponse {
  id: string;
}

export interface PutResponse extends BaseResponse {
  id: string;
}

export interface GetAllResponse<T> extends BaseResponse {
  data: T[];
}

export interface GetResponse<T> extends BaseResponse {
  data: T;
}
