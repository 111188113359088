import { Ticket } from "../../../shared/types";
import { post, get, GetResponse, GetAllResponse } from "./_base";

const path = "tickets";
export class TicketActions {
  static post = async (data: Ticket) => {
    return await post(`${path}`, data);
  };
  static confirmPayment = async (data: any) => {
    return await post(`${path}/payPal`, data);
  };
  static get = async (data: Partial<Ticket>) => {
    return (await get(`${path}`, data, {
      single: true,
    })) as GetResponse<Ticket>;
  };
  static getAll = async (data: Partial<Ticket>) => {
    return (await get(`${path}`, data)) as GetAllResponse<Ticket>;
  };
}
