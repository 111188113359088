import { useContext, useEffect, useState } from "react";
import { Ticket } from "../../../../shared/types";
import { TicketActions } from "../../actions/ticket";
import { dateToString } from "../../helpers/functions";
import { AppContext } from "../../providers/AppContextProvider";

const PurchaseHistoryTab = () => {
  const appContext = useContext(AppContext);

  const [tickets, setTickets] = useState<Ticket[]>([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await TicketActions.getAll({
      userId: appContext.user ? appContext.user.id : "",
    });

    if (response.failed) return;

    setTickets(response.data.reverse());
  };

  return (
    <div id="Compras">
      <div className="table">
        <table>
          <thead>
            <tr style={{ fontSize: "14px" }}>
              <th style={{ width: "15%" }}>Codigo</th>
              <th style={{ width: "20%" }}>Fecha de Creación</th>
              <th style={{ width: "35%" }}>Fecha de pago</th>
              <th style={{ width: "20%" }} className="importe">
                Importe
              </th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((t) => (
              <tr>
                <td>{t.id}</td>
                <td>{dateToString(new Date(t.created))}</td>
                <td>
                  {t.concretedDate
                    ? dateToString(new Date(t.concretedDate))
                    : ""}
                </td>
                <td>
                  {t.price && `${appContext.location.currencySign}${t.price}`}
                </td>
                <td>{t.concretedDate ? "Completada" : "En proceso"}</td>
                <td>
                  <button onClick={() => {}}>
                    <i className="btn-link fa fa-list"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PurchaseHistoryTab;
