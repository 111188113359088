import { Button as MUIButton } from "@material-ui/core";
import { useState } from "react";

const Button = (props: any) => {
  const [e, setE] = useState<boolean>(true);

  const handleClick = async () => {
    if (!e) return;

    setE(false);

    await props.onClick();
    setE(true);
  };

  return (
    <MUIButton {...{ ...props, onClick: undefined }} onClick={handleClick}>
      {props.children}
    </MUIButton>
  );
};

export default Button;
