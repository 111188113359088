import { useEffect, useState } from "react";
import "./NavBar.scss";
import NavBarBottom from "./NavBarBottom";
import NavBarTop from "./NavBarTop";
import Drawer from "../SideBar/Drawer";
import { useLocation } from "react-router-dom";
function NavBar() {
  const [open, setOpen] = useState(false);
  const opacity = open ? "overlay-opacity-on" : " overlay-opacity-off";
  const { pathname } = useLocation();

  useEffect(() => {
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className="navbar">
      <div className={`app-overlay ${opacity}`}></div>
      <i
        onClick={() => setOpen(true)}
        className="navbar-burgericon fa fa-bars"
      ></i>

      <Drawer open={open} handleDrawer={() => setOpen(false)} />
      <NavBarTop />

      <NavBarBottom />
    </div>
  );
}

export default NavBar;
