import { useEffect, useState } from "react";
import CountryEdit from "../components/CountryEdit";
import { Country } from "../../../../shared/types";
import { CountryActions } from "../../actions/country";
import { useNavigate, useParams } from "react-router-dom";
import { PreviewType } from "../components/EditMode";
import { LinearProgress, Box } from "@material-ui/core";

const CountryPage = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState<Country>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [isNotNew, setIsNotNew] = useState<boolean>(!!params.id);

  useEffect(() => {
    if (params.id) fetchData();
    else setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await CountryActions.get({
      id: params.id,
    });
    setCountry(response.data);
    if (!response.failed) setLoading(false);
  };
  const onSave = async (country: Country) => {
    setLoading(true);

    if (params.id) {
      await CountryActions.put(country);
    } else {
      const response = await CountryActions.post(country);

      if (!response.failed) {
        setIsNotNew(true);
        navigate(`/backend/country/${response.id}`);
      }
    }

    setCountry(country);
    setLoading(false);
  };
  const onCancel = () => {};
  const onPreview = (country: Country, type: PreviewType) => {};

  return loading ? (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : (
    <CountryEdit
      country={country}
      onSave={onSave}
      onCancel={onCancel}
      onPreview={onPreview}
      isNotNew={isNotNew}
    />
  );
};

export default CountryPage;
