import {
  Avatar,
  IconButton,
  ListItem as MUIListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Icon } from "../../helpers/types";

export interface EditModeProps {
  selected?: boolean;
  disabled?: boolean;
  icons: Icon[];
  title?: string;
  description?: string;
  image?: string;
  onClick?: () => void;
  children?: JSX.Element;
}
const useStyles = makeStyles((theme) => ({
  listItem: {
    minHeight: "62px",
  },
  icon: {
    width: "45px",
    textAlign: "center",
  },
  text: {
    paddingRight: "50px",
  },
}));

const ListItem = ({
  selected,
  disabled,
  icons,
  title,
  description,
  image,
  onClick,
  children,
}: EditModeProps) => {
  const classes = useStyles();

  const getContent = () => (
    <>
      {image && (
        <ListItemAvatar>
          <Avatar src={image} />
        </ListItemAvatar>
      )}
      <ListItemText
        classes={{ primary: classes.text }}
        primary={title}
        secondary={description}
      />
      <ListItemSecondaryAction>
        {icons.map((i, idx) => (
          <IconButton
            key={idx}
            edge="end"
            onClick={i.action}
            className={classes.icon}
          >
            {i.icon}
          </IconButton>
        ))}
      </ListItemSecondaryAction>
    </>
  );

  return (
    <>
      {onClick ? (
        <MUIListItem
          selected={selected}
          button
          disabled={disabled}
          onClick={onClick}
          className={classes.listItem}
        >
          {getContent()}
        </MUIListItem>
      ) : (
        <MUIListItem className={classes.listItem} disabled={disabled}>
          <div>
            {getContent()}
            {children}
          </div>
        </MUIListItem>
      )}
    </>
  );
};

export default ListItem;
