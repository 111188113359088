import React, { useState } from "react";
import "./Tabs.scss";

interface Tab {
  child: React.ReactNode;
  title: string;
  icon: string;
}
interface TabsProps {
  tabs: Tab[];
}

function Tabs({ tabs }: TabsProps) {
  const [activeTab, setactiveTab] = useState(tabs[0].title);

  const handleClick = (e: any, newActiveTab: string) => {
    e.preventDefault();
    setactiveTab(newActiveTab);
  };

  return (
    <div>
      <ul className="tabs">
        {tabs.map((tab, key) => {
          const { title } = tab;

          return (
            <li
              onClick={(e) => handleClick(e, tab.title)}
              className={
                title === activeTab ? "current tab-element" : "tab-element"
              }
              key={key}
            >
              <i className={tab.icon}></i>
              <a className="tab-link" href={`/${title}`}>
                {title}
              </a>
              {title === "Compras" && <span className="active-buy">1</span>}
            </li>
          );
        })}
      </ul>

      {tabs.find((tab) => tab.title === activeTab)?.child}
    </div>
  );
}

export default Tabs;
