import { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  List,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import ListItem from "../components/ListItem";
import { Country } from "../../../../shared/types";
import { CountryActions } from "../../actions/country";
import { useNavigate } from "react-router-dom";

const CountriesPage = () => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await CountryActions.getAll({});
    setCountries(response.data);
    if (!response.failed) setLoading(false);
  };

  const handleClick = (id: string) => {
    navigate(`/backend/country/${id}`);
  };

  const handleNewClick = () => {
    navigate(`/backend/country`);
  };

  return (
    <>
      <Typography>Talleres</Typography>
      <Button variant="contained" color="primary" onClick={handleNewClick}>
        Nuevo
      </Button>
      <IconButton onClick={handleNewClick}>
        <AddIcon />
      </IconButton>
      {loading ? (
        <LinearProgress />
      ) : (
        <List>
          {countries.map((w) => (
            <ListItem
              key={w.id}
              title={w.code}
              description={w.name}
              icons={[
                {
                  action: () => handleClick(w.id ?? ""),
                  icon: <EditIcon />,
                },
              ]}
              onClick={() => handleClick(w.id ?? "")}
            />
          ))}
        </List>
      )}
    </>
  );
};

export default CountriesPage;
