import Input from "../components/Input/Input";

import "./Contact.scss";

const Contact = () => {
  return (
    <>
      <form className="form-container">
        <div className="flex-wrapper">
          <div className="input-container">
            <Input type="text" label="* Apellido, Nombre" required={true} />
            <Input type="text" label="* Email" required={true} />
            <Input type="text" label="* Teléfono" required={true} />
            <textarea name="" id="" cols={30} rows={4}></textarea>
          </div>
          <div className="address-container">
            <h4>KARINA ZARFINO</h4>
            <address>
              <span>
                Teléfono:
                <a href="tel:+5491138522023"> +54 911 38522023</a>
              </span>
              <span>Whatsapp: +54 9 11 3822023</span>
              <span>CABA</span>
              <span>Buenos Aires</span>
              <span>Argentina</span>
            </address>
          </div>
          <img
            src="https://www.karinazarfino.com/datos/uploads/mod_diseno/thumbnail_log3kazar-61def6cb7fedf.png?t=1642002123" //This logo should be a .PNG with no background. I tried the static/logo but is a jpeg with white background.
            alt="Karina Zarfino logo"
          />
        </div>
        <label className="suscribe-label" htmlFor="">
          <input className="suscribe-check" type="checkbox" />
          quiero recibir novedades del sitio via e-mail.
        </label>
        <button className="form-button" style={{ display: "block" }}>
          Enviar
        </button>
        <p className="form-disclaimer">(*) Indica campos requeridos.</p>
      </form>
    </>
  );
};

export default Contact;
