import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMercadopago } from "react-sdk-mercadopago";
import { Ticket } from "../../../shared/types";
import DeliveryForm from "../components/BuyPage/DeliveryForm";
import DeliveryInformation from "../components/BuyPage/DeliveryInformation";
import Payment from "../components/BuyPage/Payment";
import { AppContext } from "../providers/AppContextProvider";
import { CartContext } from "../providers/CartContextProvider";

const PayTestPage = () => {
  const [phase, setPhase] = useState(2);

  const appContext = useContext(AppContext);
  const cartContext = useContext(CartContext);

  const navigate = useNavigate();

  const mercadopago =
    cartContext.getTicket() &&
    appContext.location.mercadoPago &&
    useMercadopago.v2(appContext.location.mercadoPago, {
      locale: "es-AR",
    });

  useEffect(() => {
    const ticket = cartContext.getTicket()?.mercadoPagoPreference;
    if (!ticket) {
      alert("ERROR ticket no encontrado");
      navigate("/");
      return;
    }

    if (mercadopago) {
      mercadopago.checkout({
        preference: {
          id: ticket,
        },
        render: {
          container: ".cho-container",
          label: "Pagar",
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mercadopago]);

  useEffect(() => {
    if (!cartContext.getCartItems().length) {
      setPhase(0);
      return;
    }
    if (cartContext.getCartItems().every((x) => !x.physical)) {
      setPhase(3);
      return;
    } else {
      setPhase(2);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartContext.getCartItems()]);

  const getCurrentPage = () => {
    switch (phase) {
      case 0:
        return <>El carro de compras esta vacio</>;
      case 1:
        return <DeliveryForm />;
      case 2:
        return <DeliveryInformation />;
      case 3:
        return (
          <Payment
            ticket={cartContext.getTicket() as Ticket}
            mercadoPago={
              appContext.location.mercadoPago ? (
                <div className="cho-container" />
              ) : undefined
            }
            payPal={appContext.location.payPal}
          />
        );
    }
  };

  return <div>{getCurrentPage()}</div>;
};

export default PayTestPage;
