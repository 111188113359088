import "./Photos.scss";
import Gallery from "../components/Gallery/Gallery";

const Photos = () => {
  const fullImg = [
    "https://images.unsplash.com/photo-1647808561202-a26e92d2d04d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "https://images.unsplash.com/photo-1647777421909-7efc6da0034f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",

    "https://images.unsplash.com/photo-1647778373834-63d69b2f6ecf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1931&q=80",
    " https://images.unsplash.com/photo-1647749726101-eb41e3e9450a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=742&q=80",

    "https://images.unsplash.com/photo-1640622307877-1e40352b9a48?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  ];

  return (
    <>
      <Gallery images={fullImg} type="photos" videos={[]} title="" />
    </>
  ); //Receives an array and a component, the component could be image or video
};

export default Photos;
