import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { Country } from "../../../../shared/types";
import { DEFAULT_COUNTRY } from "../../helpers/defaults";
import EditMode, { PreviewType } from "./EditMode";
import { Checkbox } from "./Checkbox";

const useStyles = makeStyles((theme) => ({
  countryread: {
    textAlign: "left",
    width: "100%",
    height: "100%",
  },
  title: {
    textAlign: "left",
    width: "50%",
    paddingBottom: "10px",
  },
  description: {
    textAlign: "left",
    width: "90%",
    paddingBottom: "10px",
  },
  image: {
    paddingBottom: "10px",
  },
  input: {
    display: "none",
  },
}));

interface CountryReadProps {
  country?: Country;
  isNotNew: boolean;
  onSave: (country: Country) => void;
  onCancel: () => void;
  onPreview: (country: Country, type: PreviewType) => void;
}
const CountryRead = (props: CountryReadProps) => {
  const classes = useStyles();

  const [country, setCountry] = useState<Country>(
    props.country ?? DEFAULT_COUNTRY
  );

  return (
    <EditMode
      onSave={() => props.onSave(country)}
      onCancel={props.onCancel}
      //onPreview={(type) => props.onPreview(country, type)}
    >
      <div className={classes.countryread}>
        <div>
          <TextField
            className={classes.title}
            required
            label="Código"
            variant="outlined"
            value={country.code}
            disabled={props.isNotNew}
            onChange={(e) =>
              setCountry({ ...country, code: e.target.value.toUpperCase() })
            }
          />
        </div>
        <div>
          <Checkbox
            label="Habilitado"
            onChange={(state) => setCountry({ ...country, enabled: state })}
            checked={!!country.enabled}
          />
        </div>
        <div>
          <TextField
            className={classes.title}
            required
            label="Nombre"
            variant="outlined"
            onChange={(e) => setCountry({ ...country, name: e.target.value })}
            value={country.name}
          />
        </div>
        <div>
          <TextField
            className={classes.title}
            required
            label="Moneda"
            variant="outlined"
            onChange={(e) =>
              setCountry({ ...country, currency: e.target.value })
            }
            value={country.currency}
          />
        </div>
        <div>
          <TextField
            className={classes.title}
            required
            label="Simbolo"
            variant="outlined"
            onChange={(e) =>
              setCountry({ ...country, currencySign: e.target.value })
            }
            value={country.currencySign}
          />
        </div>
        {country.mercadoPago === undefined ? (
          <div>
            <Checkbox
              label="MercadoPago"
              onChange={(state) => setCountry({ ...country, mercadoPago: "" })}
              checked={false}
            />
          </div>
        ) : (
          <>
            <Checkbox
              label="MercadoPago"
              onChange={(state) =>
                setCountry({ ...country, mercadoPago: undefined })
              }
              checked={true}
            />
            <TextField
              className={classes.title}
              required
              label="MercadoPago"
              variant="outlined"
              onChange={(e) =>
                setCountry({ ...country, mercadoPago: e.target.value })
              }
              value={country.mercadoPago}
            />
          </>
        )}
        <br />
        {country.payPal === undefined ? (
          <div>
            <Checkbox
              label="PayPal"
              onChange={(state) => setCountry({ ...country, payPal: "" })}
              checked={false}
            />
          </div>
        ) : (
          <>
            <Checkbox
              label="PayPal"
              onChange={(state) =>
                setCountry({ ...country, payPal: undefined })
              }
              checked={true}
            />
            <TextField
              className={classes.title}
              required
              label="PayPal"
              variant="outlined"
              onChange={(e) =>
                setCountry({ ...country, payPal: e.target.value })
              }
              value={country.payPal}
            />
          </>
        )}
      </div>
    </EditMode>
  );
};

export default CountryRead;
