import { useEffect, useState } from "react";
import {
  Button,
  List,
  LinearProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon, Delete } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import ListItem from "../components/ListItem";
import { Entry } from "../../../../shared/types";
import { useNavigate } from "react-router-dom";
import { EntriesActions } from "../../actions/entries";

const BlogsPage = () => {
  const navigate = useNavigate();
  const [entries, setEntries] = useState<Entry[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await EntriesActions.getAll({});
    setEntries(response.data);
    if (!response.failed) setLoading(false);
  };

  const handleClick = (id: string) => {
    navigate(`/backend/entry/${id}`);
  };

  const handleNewClick = () => {
    navigate(`/backend/entry/`);
  };

  const handleDelete = (id: string) => {
    EntriesActions.remove(id);
    window.location.reload();
  };

  return (
    <>
      <Typography>Blog</Typography>
      <Button variant="contained" color="primary" onClick={handleNewClick}>
        Nueva entrada
      </Button>
      <IconButton onClick={handleNewClick}>
        <AddIcon />
      </IconButton>
      {loading ? (
        <LinearProgress />
      ) : (
        <List>
          {entries.map((w) => (
            <ListItem
              image={w.image?.path}
              key={w.id}
              title={w.title}
              icons={[
                { action: () => handleClick(w.id ?? ""), icon: <EditIcon /> },
                { action: () => handleDelete(w.id ?? ""), icon: <Delete /> },
              ]}
            />
          ))}
        </List>
      )}
    </>
  );
};

export default BlogsPage;
