import { useEffect, useState } from "react";
import WorkshopEdit from "../components/WorkshopEdit";
import { Workshop, WorkshopType } from "../../../../shared/types";
import { WorkshopActions } from "../../actions/workshops";
import { useNavigate, useParams } from "react-router-dom";
import { PreviewType } from "../components/EditMode";
import { LinearProgress, Box } from "@material-ui/core";
import { DEFAULT_WORKSHOP } from "../../helpers/defaults";

const WorkshopPage = () => {
  const navigate = useNavigate();
  const [workshop, setWorkshop] = useState<Workshop>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const { type } = useParams();

  useEffect(() => {
    if (params.id) fetchData();
    else setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await WorkshopActions.getForBack({
      id: params.id,
    });

    if (response.failed) return;
    setWorkshop({ ...response.data, courses: response.data.courses ?? [] });
    setLoading(false);
  };
  const onSave = async (workshop: Workshop) => {
    //TODO: Make loading overlay and use that one for when page is loaded.
    //TODO: Snackbar for confirmation ( modal? )
    setLoading(true);
    let response;
    if (params.id) {
      response = await WorkshopActions.put(workshop);
    } else {
      response = await WorkshopActions.post(workshop);
      if (!response.failed) navigate(`/backend/workshop/${response.id}`);
    }

    setWorkshop(workshop);
    setLoading(false);
  };
  const onCancel = () => {};
  const onPreview = (workshop: Workshop, type: PreviewType) => {};

  return loading ? (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : (
    <WorkshopEdit
      workshop={
        workshop ?? { ...DEFAULT_WORKSHOP, workshopType: type as WorkshopType }
      }
      onSave={onSave}
      onCancel={onCancel}
      onPreview={onPreview}
    />
  );
};

export default WorkshopPage;
