import React, { useRef } from "react";
import { Course } from "../../../../shared/types";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ListItem from "./ListItem";
import { useDrag, useDrop } from "react-dnd";

interface CourseListItemProps {
  course: Course;
  disabled?: boolean;
  onRemove?: () => void;
  onEdit?: () => void;
  index: number;
  onItemDropped: (dragIndex: number, hoverIndex: number) => void;
}

const CourseListItem = ({
  course,
  disabled,
  onRemove,
  onEdit,
  index,
  onItemDropped,
}: CourseListItemProps) => {
  const getIcons = () => {
    let icons = [];

    if (onEdit) icons.push({ action: onEdit, icon: <EditIcon /> });
    if (onRemove) icons.push({ action: onRemove, icon: <DeleteIcon /> });

    return icons;
  };

  const ref = useRef(null);

  const [{ opacity }, drag] = useDrag<any, any, any>({
    type: "CourseListItem",
    item: { type: "CourseListItem", text: course.title, index: index },
    collect: (monitor) => {
      return {
        opacity: monitor.isDragging() ? 0.5 : 1,
      };
    },
  });

  const [, drop] = useDrop({
    accept: "CourseListItem",
    drop(item: any) {
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      onItemDropped && onItemDropped(dragIndex, hoverIndex);
    },
  });

  drag(drop(ref));

  drag(drop(ref));
  return (
    <div ref={ref} style={{ opacity }}>
      <ListItem
        disabled={disabled}
        image={course.image?.path}
        key={course.id}
        title={course.title}
        icons={getIcons()}
      />
    </div>
  );
};

export default CourseListItem;
