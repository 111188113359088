import { useEffect, useState } from "react";
import { AboutMe } from "../../../shared/types";
import { SectionActions } from "../actions/sections";
import CardDetails from "../components/CardDetails/CardDetails";

const AboutMePage = () => {
  const [aboutMe, setAboutMe] = useState<AboutMe>();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await SectionActions.get<AboutMe>({
      sectionName: "AboutMe",
    });

    setAboutMe(response.data);
  };

  return (
    <div>
      <CardDetails removeSocials content={aboutMe?.text} />
    </div>
  );
};

export default AboutMePage;
