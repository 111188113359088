import {
  Country,
  Course,
  Price,
  User,
  Video,
  Workshop,
  Purchase,
  Card,
  News,
  Image,
  Entry,
  Address,
} from "../../../shared/types";

export const DEFAULT_VIDEO_THUMB = "/static/img/videothumb.jpeg";

export const DEFAULT_IMAGE: Image = {
  path: "",
};

export const DEFAULT_ADDRESS: Address = {};

export const DEFAULT_COURSE: Course = {
  title: "",
  text: "",
  file: "",
  image: DEFAULT_IMAGE,
};

export const DEFAULT_ENTRY: Entry = {
  title: "",
  text: "",
  file: "",
  image: DEFAULT_IMAGE,
  date: Date.now(),
};

export const DEFAULT_CARD: Card = {
  title: "",
  description: "",
  text: "",
  image: DEFAULT_IMAGE,
};

export const DEFAULT_NEWS: News = {
  sectionName: "News",
  country: "AR",
  youtubeLink: "",
  workshops: ["", "", ""],
};

export const DEFAULT_PRICE: Price = {
  country: "NO",
  price: -1,
  discount: -1,
  enabled: false,
};

export const DEFAULT_WORKSHOP: Workshop = {
  title: "",
  workshopType: "workshop",
  description: "",
  text: "",
  prices: [],
  courses: [],
  completeCourses: [],
  price: DEFAULT_PRICE,
  type: "workshop",
  book: "",
  image: { path: "" },
};

export const DEFAULT_PURCHASE: Purchase = {
  id: "",
  type: "workshop",
  ts: Date.now(),
};

export const DEFAULT_VIDEO: Video = {
  ...DEFAULT_IMAGE,
  provider: "hosted",
  thumbnail: DEFAULT_IMAGE,
};

export const DEFAULT_COUNTRY: Country = {
  code: "",
  enabled: false,
  isDefault: false,
};

export const DEFAULT_USER: User = {
  email: "",
};
