import { useContext, useEffect, useState } from "react";
import {
  ImageList,
  ImageListItem,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { FileUploader } from "../components/FileUploader";
import { ErrorContext } from "../../providers/ErrorContextProvider";
import { PhotoActions } from "../../actions/photos";
import { Image } from "../../../../shared/types";

const PhotosPage = () => {
  const [photos, setPhotos] = useState<Image[]>([]);
  const [loading, setLoading] = useState(true);

  const errorContext = useContext(ErrorContext);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await PhotoActions.get();

    const data: Image[] = response.map((p) => ({
      path: `/${p}`,
    }));
    setPhotos(data);
    setLoading(false);
  };

  return (
    <>
      <Typography>Fotos</Typography>

      <FileUploader
        path="/imageUploadScript.php"
        uploadText={"Subir"}
        selectFileText={"Seleccionar imágen"}
        onUpload={(p) =>
          setPhotos([
            {
              path: p,
            },
            ...photos,
          ])
        }
        onError={(m) => {
          errorContext.setMessage(m);
        }}
      />
      {loading ? (
        <LinearProgress />
      ) : (
        <ImageList cols={3} rowHeight={164}>
          {photos.map((i) => (
            <ImageListItem key={i.path}>
              <img src={i.path} alt={i.path} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </>
  );
};

export default PhotosPage;
