import { createContext, useEffect, useState } from "react";
import { setErrorCallback } from "../actions/_base";

interface ErrorContent {
  message?: Message;
}

type MessageType = "error" | "success" | "warning";

export interface Link {
  text: string;
  to: string;
}

export interface Message {
  message: string;
  link?: Link;
  type: MessageType;
  permanent?: boolean;
}

interface IErrorContext extends ErrorContent {
  setMessage: (message?: Message) => void;
}

const DEFAULT: IErrorContext = {
  setMessage: () => undefined,
};

export const ErrorContext = createContext<IErrorContext>(DEFAULT);

interface ErrorContextProviderProps {
  children?: React.ReactNode;
}

const DEFAULT_WAIT_TIME = 3000;

export const ErrorContextProvider = (props: ErrorContextProviderProps) => {
  const setMessage = (message?: Message) => {
    setErrorState({ ...error, message });
    clearTimeout(timeout);
    if (!message || message.permanent) return;
    timeout = setTimeout(() => {
      setMessage();
    }, DEFAULT_WAIT_TIME);
  };

  let timeout: NodeJS.Timeout;

  const [error, setErrorState] = useState<IErrorContext>({
    ...DEFAULT,
    setMessage,
  });

  useEffect(() => {
    setErrorCallback(setMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { children } = props;

  return (
    <ErrorContext.Provider value={error}>{children}</ErrorContext.Provider>
  );
};
