import "./NavBarBottom.scss";
import { getNavigationMenu } from "../../navigationMenu";

import NavItem from "./NavItem";
import { useContext } from "react";
import { AppContext } from "../../providers/AppContextProvider";

function NavBarBottom() {
  const appContext = useContext(AppContext);
  return (
    <div className="navbarbottom">
      <img
        height="68px"
        src="/static/img/logo.jpeg"
        alt="Karina Zarfino Logo"
      />

      <ul className="navbarbottom-ul">
        {getNavigationMenu(!!appContext.user).map((navigation) => {
          const depthLevel = 0;

          return (
            <NavItem
              key={navigation.title}
              item={navigation}
              depthLevel={depthLevel}
            />
          );
        })}
      </ul>
    </div>
  );
}

export default NavBarBottom;
