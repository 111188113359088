import "./User.scss";
import Tabs from "../components/Tabs/Tabs";
import PersonalTab from "../components/UserTabs/PersonalTab";
// import AddressTab from "../components/UserTabs/AddressTab";
import PurchaseHistoryTab from "../components/UserTabs/PurchaseHistoryTab";
// import FavoritesTab from "../components/UserTabs/FavoritesTab";
import PasswordTab from "../components/UserTabs/PasswordTab";

const User = () => {
  return (
    <div className="registration-container">
      <h3>Mi cuenta</h3>
      <Tabs
        tabs={[
          {
            child: <PersonalTab />,
            icon: "fa fa-user",
            title: "Datos Personales",
          },
          // {
          //   child: <AddressTab />,
          //   icon: "fa fa-map-marker",
          //   title: "Domicilio",
          // },
          // {
          //   child: <FavoritesTab />,
          //   icon: "fa fa-heart",
          //   title: "Favoritos",
          // },
          {
            child: <PurchaseHistoryTab />,

            icon: "fa fa-shopping-cart",
            title: "Compras",
          },
          {
            child: <PasswordTab />,
            icon: "fa fa-lock",
            title: "Seguridad",
          },
        ]}
      />
    </div>
  );
};

export default User;
