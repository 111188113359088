import { Typography } from "@material-ui/core";
import { useState } from "react";
import { AboutMe } from "../../../../shared/types";
import EditMode, { PreviewType } from "./EditMode";
import TextEditorWithVideo from "./TextEditorWithVideo";

interface AboutMeEditProps {
  aboutMe: AboutMe;
  onSave: (aboutMe: AboutMe) => void;
  onCancel: () => void;
  onPreview: (aboutMe: AboutMe, type: PreviewType) => void;
}

const AboutMeEdit = (props: AboutMeEditProps) => {
  const [aboutMe, setAboutMe] = useState<AboutMe>(props.aboutMe);

  return (
    <EditMode
      onSave={() => props.onSave(aboutMe)}
      onCancel={() => props.onCancel}
    >
      <Typography variant={"h2"} style={{ textAlign: "left" }}>
        Sobre Mi
      </Typography>
      <div>
        <TextEditorWithVideo
          initialText={aboutMe.text}
          onLateUpdate={(text) => setAboutMe({ ...aboutMe, text })}
        />
      </div>
    </EditMode>
  );
};

export default AboutMeEdit;
