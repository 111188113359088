import { useState } from "react";
import FsLightbox from "fslightbox-react";
import PhotoCard from "../PhotoCard/PhotoCard";
import "./Gallery.scss";

interface GalleryProps {
  images: string[];
  type: string;
  videos: string[];
  title: any;
  //This should receive two types of arrays. Array of images and array of videos.
  //When using images, the renderedElement() and the FsLightbox can use the same array, but when using video, the renderedElement() should receive the videos, that will be used in the sources prop in FsLightbox and the PhotoCard component should be receiving images and the title to render thumbnails for the videos to display correctly.
  //The props flow and types should be reviewed according on how the data from the backend  will be received.
}

const Gallery = (props: GalleryProps) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 0,
  });

  function openLightboxOnSlide(number: any) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  const renderedElement = (arr: any, type?: string) => {
    const array = arr.map((item: any) => {
      if (type === "video") {
        return (
          <iframe title="video" src={item} width="640" height="480"></iframe>
        );
      } else {
        return <img src={item} alt="" />;
      }
    });
    return array;
  };

  const renderElem = props.type === "video" ? props.videos : props.images;

  return (
    <>
      <div className="gallery-container">
        {props.images.map((image, key) => {
          const number = key;
          return (
            <PhotoCard
              key={key}
              openSlide={() => openLightboxOnSlide(number + 1)}
              src={image}
              title={props.title}
            />
          );
        })}
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={renderedElement(renderElem, props.type)}
      />
    </>
  );
};

export default Gallery;
