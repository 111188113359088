interface VideoPreviewProps {
  path: string;
}

const VideoPreview = ({ path }: VideoPreviewProps) => {
  return (
    <video controls controlsList="nodownload" width="320" height="240">
      <source src={path} type="video/mp4" />
      Navegador incompatible
    </video>
  );
};

export default VideoPreview;
