import { AuthUser, AuthUserRequest } from "../../../shared/types";
import { get, GetResponse } from "./_base";

export class AuthUserActions {
  static get = async (data: AuthUserRequest) => {
    return (await get(`authUser`, data)) as GetResponse<AuthUser>;
  };

  static getOut = async () => {
    return await get(`authUser/getOut`);
  };
}
