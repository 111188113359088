import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect } from "react";
import { useMercadopago } from "react-sdk-mercadopago";
import { TicketActions } from "../actions/ticket";

const PayTestPage = () => {
  const mercadopago = useMercadopago.v2(
    "TEST-75b6aa4d-9845-42ca-ac48-6ce6a1283af9",
    {
      locale: "es-AR",
    }
  );

  useEffect(() => {
    // TicketActions.confirmPayment({ hola: "hola" });

    if (mercadopago) {
      mercadopago.checkout({
        preference: {
          id: "52946866-0c9c8ce6-46ad-4ccc-82af-738c37de474a",
        },
        render: {
          container: ".cho-container",
          label: "Pagar",
        },
      });
    }
  }, [mercadopago]);

  return (
    <div>
      <div className="cho-container" />
      <PayPalScriptProvider options={{ "client-id": "test" }}>
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: "1.99",
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions: any) => {
            return actions.order.capture().then(async (details: any) => {
              console.log(details);
              await TicketActions.confirmPayment(details);
            });
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default PayTestPage;
