import { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  List,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon, Delete } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import ListItem from "../components/ListItem";
import { Workshop, WorkshopType } from "../../../../shared/types";
import { WorkshopActions } from "../../actions/workshops";
import { useNavigate, useParams } from "react-router-dom";

const WorkshopsPage = () => {
  const navigate = useNavigate();
  const [workshops, setWorkshops] = useState<Workshop[]>([]);
  const [loading, setLoading] = useState(true);
  const { type } = useParams();

  useEffect(() => {
    setWorkshops([]);
    setLoading(true);
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const fetchData = async () => {
    const response = await WorkshopActions.getAllForBack({
      workshopType: type as WorkshopType,
    });
    setWorkshops(response.data);
    if (!response.failed) setLoading(false);
  };

  const handleClick = (id: string) => {
    navigate(`/backend/workshop/${id}`);
  };

  const handleDelete = async (id: string) => {
    const response = await WorkshopActions.remove(id);

    if (response.failed) return;

    setWorkshops(workshops.filter((w) => w.id !== id));
  };

  const handleNewClick = () => {
    navigate(`/backend/newworkshop/${type}`);
  };

  return (
    <>
      <Typography>Talleres</Typography>
      <Button variant="contained" color="primary" onClick={handleNewClick}>
        Nuevo
      </Button>
      <IconButton onClick={handleNewClick}>
        <AddIcon />
      </IconButton>
      {loading ? (
        <LinearProgress />
      ) : (
        <List>
          {workshops.map((w) => (
            <ListItem
              image={w.image?.path}
              key={w.id}
              title={w.title}
              description={w.description}
              icons={[
                {
                  action: () => handleClick(w.id ?? ""),
                  icon: <EditIcon />,
                },
                {
                  action: () => handleDelete(w.id ?? ""),
                  icon: <Delete />,
                },
              ]}
            />
          ))}
        </List>
      )}
    </>
  );
};

export default WorkshopsPage;
