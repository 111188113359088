import { useContext, useState } from "react";
import { Price } from "../../../../shared/types";
import PriceView from "./PriceView";
import PriceEdit from "./PriceEdit";
import { Divider, Typography, List } from "@material-ui/core";
import Button from "./Button";
import { AppContext } from "../../providers/AppContextProvider";

interface PricesProps {
  prices: Price[];
  onChange: (prices: Price[]) => void;
}

const Prices = ({ prices, onChange }: PricesProps) => {
  const [addNew, setAddNew] = useState<boolean>(false);
  const [priceIndex, setPriceIndex] = useState<number>(0);
  const [priceEditing, setPriceEditing] = useState<Price>();

  const appContext = useContext(AppContext);

  const handleRemovePrice = (idx: number) => {
    const nprices = [...prices];
    nprices.splice(idx, 1);
    onChange(nprices);
  };

  return (
    <>
      <Typography variant="h5">Precios</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setAddNew(true)}
        style={{ padding: "10px" }}
      >
        Agregar
      </Button>

      <List>
        {addNew && (
          <PriceEdit
            countries={appContext.countries.filter(
              (x) => !prices.find((p) => p.country === x.code)
            )}
            onCancel={() => setAddNew(false)}
            onSave={(p: Price) => {
              onChange([...prices, p]);
              setAddNew(false);
            }}
          />
        )}
        {priceEditing && (
          <PriceEdit
            oldPrice={priceEditing}
            countries={appContext.countries}
            onCancel={() => setPriceEditing(undefined)}
            onSave={(p: Price) => {
              const nprices = [...prices];
              nprices[priceIndex] = p;
              onChange(nprices);
              setPriceEditing(undefined);
            }}
          />
        )}
        {prices.map((p, idx) => (
          <>
            <PriceView
              key={`${idx}${p.country}${p.price}${p.discount}`}
              price={p}
              onEdit={() => {
                setPriceIndex(idx);
                setPriceEditing(p);
              }}
              onRemove={() => handleRemovePrice(idx)}
            />
            {prices.length !== idx + 1 && (
              <Divider variant="inset" component="li" />
            )}
          </>
        ))}
      </List>
    </>
  );
};

export default Prices;
