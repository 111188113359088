import React, { useEffect, useState } from "react";
import Drawer from "../components/Drawer";
import NavBar from "../components/NavBar";
import Paper from "@material-ui/core/Paper/Paper";
import { useLocation } from "react-router-dom";
export interface BackendProps {
  children?: React.ReactNode;
}

const Backend = ({ children }: BackendProps) => {
  const [drawerState, setDrawerState] = useState(false);

  const handleOnMenuClicked = () => setDrawerState(!drawerState);
  const { pathname } = useLocation();

  useEffect(() => {
    setDrawerState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div>
      <NavBar onMenuClicked={handleOnMenuClicked} />
      <Drawer open={drawerState} />
      <div style={{ backgroundColor: "white", padding: "74px 20% 10px 20%" }}>
        <Paper style={{ padding: "10px" }} elevation={3} variant="outlined">
          {children}
        </Paper>
      </div>
    </div>
  );
};

export default Backend;
