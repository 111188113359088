import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardType } from "../../../shared/types";
import { CardActions } from "../actions/cards";
import { EntriesActions } from "../actions/entries";
import CardDetails from "../components/CardDetails/CardDetails";

const Workshop = () => {
  const [card, setCard] = useState<Card>();
  const { id } = useParams();
  const { type } = useParams();

  useEffect(() => {
    fetchData(type as CardType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (type: CardType) => {
    switch (type) {
      case "entry":
        const entry = await EntriesActions.get({ id: id });
        setCard({ ...entry.data, description: "" });
        break;
      default:
        const card = await CardActions.get({ type, id: id });
        setCard(card.data);
        break;
    }
  };

  return (
    <div>
      <CardDetails
        image={card?.image?.path}
        title={card?.title}
        description={card?.description}
        content={card?.text}
      />
    </div>
  );
};

export default Workshop;
