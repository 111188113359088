import { useEffect } from "react";
import { AuthUserActions } from "../actions/authUser";

const GetOutPage = () => {
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    await AuthUserActions.getOut();
  };

  return <></>;
};

export default GetOutPage;
