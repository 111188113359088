import { Course } from "../../../shared/types";
import { post, get, GetResponse, GetAllResponse, put } from "./_base";

const path = "courses";

export class CourseActions {
  static post = async (data: Course) => {
    return await post(path, data);
  };
  static get = async (data: Partial<Course>) => {
    return (await get(path, data, {
      single: true,
    })) as GetResponse<Course>;
  };
  static getAll = async (data: Partial<Course>) => {
    return (await get(path, data)) as GetAllResponse<Course>;
  };
  static put = async (data: Course) => {
    return await put(path, data);
  };
}
