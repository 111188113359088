import { InCartItem } from "../providers/CartContextProvider";

interface BuyDetailsArticleProps {
  article: InCartItem;
}

export const BuyDetailsArticle = ({ article }: BuyDetailsArticleProps) => {
  return (
    <tr className="border-bottom">
      <td>
        <div className="detail-article">
          <img className="img" src={article.image?.path} alt="" />
        </div>
      </td>
      <td>
        <div>
          <p className="item-title">{article.title}</p>
          <p className="item-code">
            <span>Código:</span> 000
          </p>
        </div>
      </td>
      <td className="text-right" style={{ width: "30%" }}>
        1
      </td>
      <td className="text-right">{article.price.price}</td>
      <td className="text-right">2.900,00</td>
    </tr>
  );
};
