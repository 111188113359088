import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../../providers/AppContextProvider";
import { ErrorContext, Message } from "../../providers/ErrorContextProvider";
import Backend from "../components/Backend";
import Modal from "../components/Modal";

interface Props {
  component: React.ComponentType;
}

const BasePage: React.FC<Props> = ({ component: RouteComponent }) => {
  const errorContext = useContext(ErrorContext);
  const appContext = useContext(AppContext);

  const isAuthenticated = !!appContext.user;

  const [message, setMessage] = useState<Message>();

  useEffect(() => {
    if (errorContext.message) setMessage(errorContext.message);
  }, [errorContext.message]);

  if (!isAuthenticated || !appContext.user?.roles?.some((r) => r === "admin"))
    return <Navigate to="/" />;

  return (
    <Backend>
      <RouteComponent />
      {message && (
        <Modal cancelText={"Cerrar"} onCancel={() => setMessage(undefined)}>
          {message.message}
        </Modal>
      )}
    </Backend>
  );
};

export default BasePage;
