import {
  makeStyles,
  createStyles,
  Theme,
  FormControl,
  FormControlLabel,
  Checkbox as MUICheckbox,
} from "@material-ui/core";

interface CheckboxProps {
  checked?: boolean;
  onChange: (state: boolean) => void;
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    text: {
      position: "relative",
      flexDirection: "column",
      display: "inline-flex",
      padding: "12px",
    },
  })
);

export const Checkbox = ({ checked, onChange, label }: CheckboxProps) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormControlLabel
        labelPlacement="start"
        control={
          <MUICheckbox
            name="enabled"
            checked={checked}
            onChange={() => onChange(!checked)}
          />
        }
        label={label}
      />
    </FormControl>
  );
};
