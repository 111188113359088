import "./Payment.scss";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { TicketActions } from "../../actions/ticket";
import { ErrorContext } from "../../providers/ErrorContextProvider";
import { Ticket } from "../../../../shared/types";

interface PaymentProps {
  mercadoPago?: JSX.Element;
  payPal?: string;
  ticket: Ticket;
}

const Payment = ({ mercadoPago, payPal, ticket }: PaymentProps) => {
  const navigate = useNavigate();

  const errorContext = useContext(ErrorContext);

  useEffect(() => {
    if (!mercadoPago && !payPal) {
      alert("Error obteniendo metodos de pago");
      navigate("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="payment-container">
      <div className="payment-title">
        <span>Formas de pago</span>
      </div>
      <div>
        {mercadoPago && (
          <div className="payment-info-container">
            <div className="payment-radio">
              <label htmlFor="">Pagar con MercadoPago</label>
            </div>
            <div className="payment-mercadopago-img">
              <img
                src="https://www.mercadopago.com/org-img/MP3/API/logos/visa.gif"
                alt="Visa"
                title="Visa"
              />
              <img
                src="https://www.mercadopago.com/org-img/MP3/API/logos/master.gif"
                alt="Mastercard"
                title="Mastercard"
              />
              <img
                src="https://www.mercadopago.com/org-img/MP3/API/logos/amex.gif"
                alt="American Express"
                title="American Express"
              />
              <img
                src="https://www.mercadopago.com/org-img/MP3/API/logos/naranja.gif"
                alt="Naranja"
                title="Naranja"
              />
              <img
                src="https://www.mercadopago.com/org-img/MP3/API/logos/nativa.gif"
                alt="Nativa Mastercard"
                title="Nativa Mastercard"
              />
              <img
                src="https://www.mercadopago.com/org-img/MP3/API/logos/tarshop.gif"
                alt="Tarjeta Shopping"
                title="Tarjeta Shopping"
              />
              <img
                src="https://www.mercadopago.com/org-img/MP3/API/logos/cencosud.gif"
                alt="Cencosud"
                title="Cencosud"
              />
              <img
                src="https://www.mercadopago.com/org-img/MP3/API/logos/cabal.gif"
                alt="Cabal"
                title="Cabal"
              />
              <img
                src="https://www.mercadopago.com/org-img/MP3/API/logos/argencard.gif"
                alt="Argencard"
                title="Argencard"
              />
              <img
                src="https://www.mercadopago.com/org-img/MP3/API/logos/redlink.gif"
                alt="RedLink"
                title="RedLink"
              />
              <img
                src="https://www.mercadopago.com/org-img/MP3/API/logos/banelco.gif"
                alt="Banelco"
                title="Banelco"
              />
              {mercadoPago}
            </div>
          </div>
        )}
        {payPal && (
          <PayPalScriptProvider options={{ "client-id": payPal }}>
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value:
                          ticket.price?.toString() ?? "99999999999999999999999",
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions: any) => {
                return actions.order.capture().then(async (details: any) => {
                  if (
                    details.status === "SUCCESS" ||
                    details.status === "COMPLETED"
                  ) {
                    await TicketActions.confirmPayment({
                      details,
                      ticket,
                      ticketId: ticket.id,
                    });
                    errorContext.setMessage({
                      type: "success",
                      message: "Compra completada",
                    });
                    navigate("/clearCart");
                  } else
                    errorContext.setMessage({
                      type: "error",
                      message: "Error procesando el pago",
                    });
                });
              }}
            />
          </PayPalScriptProvider>
        )}
        {/* <div className="payment-info-container">
          <div className="payment-radio">
            <label htmlFor="">
              <button className="payment-radio">
                Coordinar otro método de pago
              </button>
            </label>
          </div>
        </div> */}
        {/* <div className="payment-form">
              <p>Completar datos de facturación</p>
              <div className="payment-form-wrapper">
                <label htmlFor="">
                  Provincia
                  <select>
                    <option defaultChecked value="">
                      Seleccionar
                    </option>
                    <option value="">CABA</option>
                  </select>
                </label>
                <Input type="text" label="Ciudad" />
              </div>
              <div className="payment-form-wrapper">
                <Input type="text" label="Código Postal" />
                <Input type="text" label="Dirección" />
              </div>
            </div> */}
      </div>
      <div className="payment-buttons">
        <button className="shiptype-left-btn" onClick={() => navigate("/cart")}>
          <i className="fa fa-chevron-left"></i>
          ANTERIOR
        </button>
      </div>
    </div>
  );
};

export default Payment;
