import axios from "axios";
import { CardType, Country, CountryCode, Price } from "../../../shared/types";

export const isDevEnv = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const isObject = (objValue: any) => {
  return (
    objValue &&
    objValue !== 0 &&
    objValue &&
    typeof objValue === "object" &&
    objValue.constructor === Object
  );
};

export const uploadBlobFile = (
  b: Blob,
  filename: string,
  s: (url: string) => void,
  f: (error: string) => void
) => {
  const formData = new FormData();
  formData.append("file[]", b, filename);

  axios
    .post("/fileUploadScript.php", formData)
    .then((res: any) => {
      s(res.data[0].url);
    })
    .catch((err: any) => f("Error subiendo el archivo"));
};

export const uploadFile = (
  path: string,
  file: File,
  s: (url: string) => void,
  f: (error: string) => void
) => {
  const formData = new FormData();
  formData.append("file[]", file);

  axios
    .post(path, formData)
    .then((res: any) => {
      s(res.data[0].url);
    })
    .catch((err: any) => f("Error subiendo el archivo"));
};

export const finalPriceWithDiscount = (price: number, discount: number) =>
  discount <= 0 ? price : price - price * (discount / 100);

export const priceFinal = (price: Price) =>
  finalPriceWithDiscount(price.price, price.discount);

export const getCountrySign = (
  countryCode: CountryCode,
  countries: Country[]
) => countries.find((c) => c.code === countryCode)?.currencySign;

export const dateToString = (date: Date) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = date.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getTypeNamePlural = (type: CardType) => {
  switch (type) {
    case "canalization":
      return "Canalizaciones";
    case "meditation":
      return "Meditaciones";
    case "interviews":
      return "Entrevistas";
    case "radio":
      return "Shows radio";
    case "talks":
      return "Hablando con Karina";
  }
};
