import { Country, Workshop } from "../../../shared/types";
import { post, get, GetResponse, GetAllResponse, put, remove } from "./_base";

const path = "workshops";

export class WorkshopActions {
  static post = async (data: Workshop) => {
    return await post(path, data);
  };
  static get = async (data: Partial<Workshop | Pick<Country, "code">>) => {
    return (await get(path, data, {
      single: true,
    })) as GetResponse<Workshop>;
  };
  static getForOwner = async (data: Partial<Workshop>) => {
    return (await get(`${path}/owner`, data, {
      single: true,
    })) as GetResponse<Workshop>;
  };
  static getForBack = async (data: Partial<Workshop>) => {
    return (await get(`${path}/back`, data, {
      single: true,
    })) as GetResponse<Workshop>;
  };
  static getAll = async (data: Partial<Workshop | Pick<Country, "code">>) => {
    return (await get(path, data)) as GetAllResponse<Workshop>;
  };
  static getAllForOwner = async (data: Partial<Workshop>) => {
    return (await get(`${path}/owner`, data)) as GetAllResponse<Workshop>;
  };
  static getAllForBack = async (data: Partial<Workshop>) => {
    return (await get(`${path}/back`, data)) as GetAllResponse<Workshop>;
  };
  static put = async (data: Workshop) => {
    return await put(path, data);
  };
  static remove = async (id: string) => {
    return await remove(path, id);
  };
}
