import { Section } from "../../../shared/types";
import { get, GetOptions, GetResponse, post, put } from "./_base";

export class SectionActions {
  static async get<T>(data: Section & Partial<T>, options?: GetOptions) {
    return (await get(`sections`, data, {
      single: true,
      ...(options ?? {}),
    })) as GetResponse<T>;
  }
  static async put<T>(data: Section & T) {
    return await put("sections", data);
  }
  static async post(data: Section) {
    return await post("sections", data);
  }
}
