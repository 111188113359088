import React, { useState } from "react";
import "./DrawerItem.scss";
import { Link } from "react-router-dom";
import SubDrawer from "./SubDrawer";
import { NavMenuItem } from "../../navigationMenu";

interface DrawerItemProps {
  depthLevel?: any;
  navigation: NavMenuItem;
}

function DrawerItem(props: DrawerItemProps) {
  const { navigation, depthLevel } = props;
  const [openItem, setOpenItem] = useState(false);

  return (
    <>
      <li className="item-li">
        {navigation.submenu ? (
          <>
            <div className="internal-drawer">
              {props.depthLevel > 0 ? (
                <div className="link-container">
                  <Link to={navigation.url ?? "#"} className="item-a">
                    {navigation.title}
                  </Link>
                  <span
                    className="sublevel"
                    onClick={() => setOpenItem(!openItem)}
                  ></span>
                </div>
              ) : (
                <div className="link-container">
                  <Link to={navigation.url ?? "#"} className="item-a">
                    {navigation.title}
                  </Link>
                  <span
                    className="sublevel"
                    onClick={() => setOpenItem(!openItem)}
                  ></span>
                </div>
              )}
            </div>
            <SubDrawer
              depthLevel={depthLevel}
              submenus={navigation.submenu}
              openItem={openItem}
              setOpenItem={() => setOpenItem(false)}
            />
          </>
        ) : (
          <Link className="item-a" to={navigation.url ?? "#"}>
            {navigation.title}
          </Link>
        )}
      </li>
    </>
  );
}

export default DrawerItem;
