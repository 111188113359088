import "./Input.scss";
import ErrorInput from "./ErrorInput";

interface InputProps {
  type?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  labelStyle?: any;
  value?: any;
  onChange?: (value: any) => void;
  name?: string;
  inputStyle?: any;
  customClassName?: any;
  labelLeft?: string;
}

const Input = ({
  type,
  label,
  placeholder,
  labelStyle,
  value,
  onChange,
  name,
  inputStyle,
  customClassName,
  labelLeft,
}: InputProps) => {
  const inputIncomplete = true; //This controls if the border of the input is red or not when the input is incomplete
  const inputComplete = true;
  const errorMsg = false; //This displays the ErrorMsg component that's used when completing the buying forms. It will be displayed in all the input components so I think it should have a prop to avoid getting activated in input that are not related (like WhatsappButton, etc)
  return (
    <label style={labelStyle} className="label">
      {label && label}
      <input
        className={`input ${customClassName} ${
          inputIncomplete && errorMsg && "inputErrorMsg"
        } ${inputComplete && "inputCompleted"} ${
          inputIncomplete && "inputIncomplete"
        }`}
        style={inputStyle}
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
      {inputIncomplete && errorMsg && <ErrorInput />}

      {labelLeft && labelLeft}
    </label>
  );
};

export default Input;
