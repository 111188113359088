import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../providers/AppContextProvider";
import { CartContext } from "../../providers/CartContextProvider";
import Socials from "../Socials/Socials";
import "./NavBarTop.scss";

const NavBarTop = () => {
  const appContext = useContext(AppContext);
  const cartContext = useContext(CartContext);

  const isLogged = appContext.user && appContext.user.token;
  const initiatedBuy = false;

  const calculateCartItems = () => {
    const itemCount = cartContext.getCartItems().reduce((prev, current) => {
      return current.quantity + prev;
    }, 0);

    return itemCount;
  };

  return (
    <div className="navbartop">
      <div className="navbartop-text">
        <span className="navbartop-title">Karina Zarfino</span>
        <span className="navbartop-subtitle">
          Arte, Comunicación y Terapia Psicoenergética
        </span>
      </div>
      <ul className="navbartop-user">
        <Socials className="navbartop-socials" />
        <li className="navbartop-li">
          <Link className="navbartop-link" to={"/cart"} title={"carrito"}>
            <span className="navbartop-fa fa fa-shopping-cart" />
            <span className="cart-badge">{calculateCartItems()}</span>
          </Link>
        </li>
        {/* <li className="navbartop-li">
          <Link to={"/"} title={"favoritos"} className="navbartop-link">
            <span className={"navbartop-fa fa fa-heart"} />
            <span className="cart-badge">{cartContext.getCartItems().length}</span>
          </Link>
        </li> */}
        <>
          {isLogged ? (
            <div className="test">
              <li className="navbartop-li">
                <Link
                  to={"/user"}
                  title={"user"}
                  className="navbartop-login navbartop-link"
                >
                  <span className={"navbartop-fa fa fa-user"} />
                  Mi Cuenta
                  {initiatedBuy && <span className="account-badge">1</span>}
                </Link>
              </li>
              <li className="navbartop-li">
                <Link
                  to={"#"}
                  title={"logout"}
                  className="navbartop-login navbartop-link"
                  onClick={() => appContext.setUser()}
                >
                  <span className={"navbartop-fa fa fa-sign-out"} />
                  Salir
                </Link>
              </li>
              {appContext.user?.roles?.some((r) => r === "admin") && (
                <li className="navbartop-li">
                  <Link
                    to={"/backend"}
                    title={"backend"}
                    className="navbartop-link"
                  >
                    <span className="navbartop-signup">Backend</span>
                  </Link>
                </li>
              )}
            </div>
          ) : (
            <>
              <li className="navbartop-li">
                <Link
                  to={"/login"}
                  title={"login"}
                  className="navbartop-login navbartop-link"
                >
                  <span className={"navbartop-fa fa fa-user"} />
                  Entrar
                </Link>
              </li>
              <li className="navbartop-li">
                <Link
                  to={"/register"}
                  title={"register"}
                  className="navbartop-link"
                >
                  <span className="navbartop-signup">Crear cuenta</span>
                </Link>
              </li>
            </>
          )}
        </>
      </ul>
    </div>
  );
};
export default NavBarTop;
