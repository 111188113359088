import { useContext, useState } from "react";
import { UserActions } from "../../actions/user";
import { ErrorContext } from "../../providers/ErrorContextProvider";
import Input from "../Input/Input";

const PasswordTab = () => {
  const errorContext = useContext(ErrorContext);

  const [state, setState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmation: "",
  });

  const handlePasswordChange = async () => {
    const { oldPassword, newPassword } = state;
    const response = await UserActions.changePassword({
      oldPassword,
      newPassword,
    });
    if (response.failed) return;
    errorContext.setMessage({
      message: "Contraseña cambiada",
      type: "success",
    });
  };

  return (
    <div id="Seguridad">
      <span className="registration-security-helper">
        Deje los campos en blanco para conservar la contraseña actual.
      </span>
      <span className="registration-security-helper">
        La contraseña debe tener al menos 6 caracteres.
      </span>
      <Input
        label="Vieja Contraseña"
        type="password"
        value={state.oldPassword}
        onChange={(e) => setState({ ...state, oldPassword: e })}
      />
      <Input
        label="Nuevea Contraseña"
        type="password"
        value={state.newPassword}
        onChange={(e) => setState({ ...state, newPassword: e })}
      />
      <Input
        label="Repetir Contraseña"
        type="password"
        value={state.confirmation}
        onChange={(e) => setState({ ...state, confirmation: e })}
      />
      <button className="form-button" onClick={handlePasswordChange}>
        Enviar
      </button>
    </div>
  );
};

export default PasswordTab;
