import LoginCard from "../components/LoginCard/LoginCard";
import "./Login.css";

const Login = () => {
  return (
    <div className="login">
      <LoginCard />
    </div>
  );
};

export default Login;
