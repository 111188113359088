import { Link } from "react-router-dom";
import "./RedButton.css";

interface RedButtonProps {
  to?: string;
  text: string;
  onClick?: () => void;
  linkCustomClass?: any;
  containerCustomClass?: any;
  icon?: any;
}

const RedButton = ({
  to,
  text,
  onClick,
  linkCustomClass,
  containerCustomClass,
  icon,
}: RedButtonProps) => {
  return (
    <div className={`redbutton-container ${containerCustomClass}`}>
      <Link
        to={onClick || !to ? "#" : to}
        onClick={onClick}
        className={`redbutton ${linkCustomClass}`}
      >
        {text}
        {icon}
      </Link>
    </div>
  );
};

export default RedButton;
