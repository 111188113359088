import { useEffect, useState } from "react";
import EntryEdit from "../components/EntryEdit";
import { Entry } from "../../../../shared/types";
import { useNavigate, useParams } from "react-router-dom";
import { PreviewType } from "../components/EditMode";
import { LinearProgress, Box } from "@material-ui/core";
import { EntriesActions } from "../../actions/entries";

const BlogPage = () => {
  const navigate = useNavigate();
  const [entry, setEntry] = useState<Entry>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  useEffect(() => {
    if (params.id) fetchData();
    else setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await EntriesActions.get({
      id: params.id,
    });
    setEntry(response.data);
    if (!response.failed) setLoading(false);
  };
  const onSave = async (entry: Entry) => {
    //TODO: Make loading overlay and use that one for when page is loaded.
    //TODO: Snackbar for confirmation ( modal? )
    setLoading(true);

    if (params.id) {
      await EntriesActions.put(entry);
    } else {
      const response = await EntriesActions.post(entry);

      if (!response.failed) navigate(`/backend/entry/${response.id}`);
    }

    setEntry(entry);
    setLoading(false);
  };
  const onCancel = () => {
    navigate("/backend/blog");
  };
  const onPreview = (entry: Entry, type: PreviewType) => {};

  return loading ? (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : (
    <EntryEdit
      entry={entry}
      onSave={onSave}
      onCancel={onCancel}
      onPreview={onPreview}
    />
  );
};

export default BlogPage;
