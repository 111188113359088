import { Typography, Paper, TextField, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useContext, useState } from "react";
import { Workshop } from "../../../../shared/types";
import EditMode, { PreviewType } from "./EditMode";
import FileInput from "./FileInput";
import TextEditorWithVideo from "./TextEditorWithVideo";
import PriceList from "./PriceList";
import CourseList from "./CourseList";
import { FileUploader } from "./FileUploader";
import { ErrorContext } from "../../providers/ErrorContextProvider";
import { Delete } from "@material-ui/icons";
import { DEFAULT_IMAGE } from "../../helpers/defaults";
import { Checkbox } from "./Checkbox";

const useStyles = makeStyles((theme) => ({
  workshopread: {
    textAlign: "left",
    width: "100%",
  },
  title: {
    textAlign: "left",
    width: "50%",
    paddingBottom: "10px",
  },
  description: {
    textAlign: "left",
    width: "90%",
    paddingBottom: "10px",
  },
  image: {
    paddingBottom: "10px",
  },
  input: {
    display: "none",
  },
}));

export const CHARS_TO_REMOVE = "/uploads/xxxxxx-".length;

interface WorkshopReadProps {
  workshop: Workshop;
  onSave: (workshop: Workshop) => void;
  onCancel: () => void;
  onPreview: (workshop: Workshop, type: PreviewType) => void;
}

const WorkshopRead = (props: WorkshopReadProps) => {
  const classes = useStyles();
  const errorContext = useContext(ErrorContext);

  const getTitle = () => {
    switch (workshop.workshopType) {
      case "book":
        return "Nuevo Libro";
      case "tesa":
        return "Nuevo TESA";
      case "workshop":
        return "Nuevo Taller";
    }
  };

  const [workshop, setWorkshop] = useState<Workshop>(props.workshop);

  const handleCoursesChange = (courses: string[]) => {
    setWorkshop({ ...workshop, courses });
  };

  return (
    <EditMode
      onSave={() => props.onSave(workshop)}
      onCancel={() => props.onCancel}
      //onPreview={(type) => props.onPreview(workshop, type)}
    >
      <div className={classes.workshopread}>
        <Typography variant="h4">
          {workshop.id ? workshop.title : getTitle()}
        </Typography>

        <div className={classes.image}>
          <FileInput
            type={"image"}
            path={workshop.image?.path}
            onUpload={(image: string) =>
              setWorkshop({ ...workshop, image: { path: image } })
            }
            onRemove={() => {
              setWorkshop({ ...workshop, image: DEFAULT_IMAGE });
            }}
          />
        </div>
        <div>
          <TextField
            className={classes.title}
            required
            label="Títutlo"
            variant="outlined"
            onChange={(e) =>
              setWorkshop({ ...workshop, title: e.target.value })
            }
            value={workshop.title}
          />
        </div>
        <div>
          <TextField
            className={classes.title}
            label="Tiempo de validez en meses"
            variant="outlined"
            type="number"
            disabled={
              workshop.validityTime === -1 ||
              workshop.validityTime === undefined
            }
            onChange={(e) => {
              const number = parseInt(e.target.value);
              setWorkshop({ ...workshop, validityTime: number });
            }}
            value={workshop.validityTime ?? -1}
          />
          <Checkbox
            label="Tiempo limitado"
            checked={
              workshop.validityTime !== -1 &&
              workshop.validityTime !== undefined
            }
            onChange={(state) => {
              setWorkshop({ ...workshop, validityTime: state ? 0 : -1 });
            }}
          />
        </div>
        <div>
          <TextField
            className={classes.description}
            label={"Descripción corta"}
            variant="outlined"
            onChange={(e) =>
              setWorkshop({ ...workshop, description: e.target.value })
            }
            value={workshop.description}
          />
        </div>
        <div>
          <Paper variant="outlined">
            <PriceList
              onChange={(price) => setWorkshop({ ...workshop, prices: price })}
              prices={workshop.prices}
            />
          </Paper>
        </div>
        <div>
          <Paper variant="outlined">
            {workshop.workshopType === "book" ? (
              workshop.book ? (
                <>
                  <Typography variant="h5">Descargable</Typography>
                  <p>
                    Nombre de archivo: {workshop?.book.slice(CHARS_TO_REMOVE)}
                  </p>
                  <a href={workshop?.book} download>
                    Descargar
                  </a>
                  <IconButton
                    onClick={() => setWorkshop({ ...workshop, book: "" })}
                    style={{ padding: "10px" }}
                  >
                    <Delete></Delete>
                  </IconButton>
                </>
              ) : (
                <FileUploader
                  path="/fileUploadScript.php"
                  uploadText={"Subir"}
                  selectFileText={"Seleccionar archivo"}
                  onUpload={(book) => setWorkshop({ ...workshop, book })}
                  onError={(m) => errorContext.setMessage(m)}
                  title={"Descargable"}
                />
              )
            ) : (
              <CourseList
                onChange={(courses) => setWorkshop({ ...workshop, courses })}
                courses={workshop.courses}
                onChangeCourses={handleCoursesChange}
              />
            )}
          </Paper>
        </div>
        <div>
          <Typography variant="h5">Descripción completa</Typography>
          <TextEditorWithVideo
            initialText={workshop.text}
            onLateUpdate={(text) => setWorkshop({ ...workshop, text })}
          />
        </div>
      </div>
    </EditMode>
  );
};

export default WorkshopRead;
