import { Paper, Typography } from "@material-ui/core";
import Button from "./Button";
import { makeStyles } from "@material-ui/core/styles";
import { useContext, useEffect, useState } from "react";
import { Purchase, User, Workshop } from "../../../../shared/types";
import { UserActions } from "../../actions/user";
import { WorkshopActions } from "../../actions/workshops";
import { DEFAULT_USER } from "../../helpers/defaults";
import { ErrorContext } from "../../providers/ErrorContextProvider";
import EditMode, { PreviewType } from "./EditMode";
import PurchaseList from "./PurchaseList";

const useStyles = makeStyles((theme) => ({
  userread: {
    textAlign: "left",
    width: "100%",
  },
  title: {
    textAlign: "left",
    width: "50%",
    paddingBottom: "10px",
  },
  description: {
    textAlign: "left",
    width: "90%",
    paddingBottom: "10px",
  },
  image: {
    paddingBottom: "10px",
  },
  input: {
    display: "none",
  },
}));

interface UserReadProps {
  user?: User;
  onSave: (user: User) => void;
  onCancel: () => void;
  onPreview: (user: User, type: PreviewType) => void;
}
const UserRead = (props: UserReadProps) => {
  const classes = useStyles();

  const [user, setUser] = useState<User>(props.user ?? DEFAULT_USER);
  const [loading, setLoading] = useState<boolean>(true);
  const [workshops, setWorkshops] = useState<Workshop[]>([]);

  const errorContext = useContext(ErrorContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await WorkshopActions.getAllForBack({});

    setWorkshops(response.data);
    if (!response.failed) setLoading(false);
  };

  const handlePasswordReset = async () => {
    if (!user.id) return;

    const response = await UserActions.resetUserPassword(user.id);
    if (response.failed) return;
    errorContext.setMessage({
      message: "Contraseña enviada al mail",
      type: "success",
    });
  };

  return (
    <EditMode
      onSave={() => props.onSave(user)}
      onCancel={() => props.onCancel}
      //onPreview={(type) => props.onPreview(user, type)}
    >
      <div className={classes.userread}>
        <div>
          <Typography variant="h6">{user.email}</Typography>
          <p>{`${user.firstname ?? ""} ${user.lastname ?? ""}`}</p>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePasswordReset}
            style={{ padding: "10px" }}
          >
            Restablecer contraseña
          </Button>
        </div>
        <div>
          {loading ? (
            "Cargando compras"
          ) : (
            <Paper variant="outlined">
              <PurchaseList
                workshops={workshops}
                onChange={(p: Purchase[]) => setUser({ ...user, purchases: p })}
                purchases={user.purchases ?? []}
              />
            </Paper>
          )}
        </div>
      </div>
    </EditMode>
  );
};

export default UserRead;
