import { useEffect, useState } from "react";
import VideoEdit from "../components/VideoEdit";
import { Video } from "../../../../shared/types";
import { VideoActions } from "../../actions/videos";
import { useNavigate, useParams } from "react-router-dom";
import { PreviewType } from "../components/EditMode";
import { LinearProgress, Box } from "@material-ui/core";

const VideoPage = () => {
  const navigate = useNavigate();
  const [video, setVideo] = useState<Video>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  useEffect(() => {
    if (params.id) fetchData();
    else setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const response = await VideoActions.get({
      id: params.id,
    });
    setVideo({ ...response.data });
    setLoading(false);
  };
  const onSave = async (video: Video) => {
    //TODO: Make loading overlay and use that one for when page is loaded.
    //TODO: Snackbar for confirmation ( modal? )
    setLoading(true);
    if (params.id) {
      await VideoActions.put(video);
    } else {
      const response = await VideoActions.post(video);
      if (!response.failed) navigate(`/backend/video/${response.id}`);
    }
    setVideo(video);
    setLoading(false);
  };
  const onCancel = () => {};
  const onPreview = (video: Video, type: PreviewType) => {};

  return loading ? (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : (
    <VideoEdit
      video={video}
      onSave={onSave}
      onCancel={onCancel}
      onPreview={onPreview}
    />
  );
};

export default VideoPage;
